{ Link } = require 'react-router'
React = require 'react'
Connect = require '../../helpers/Connect'
CFilterElement = require '../../cmp/filters/CFilterElement'
CMultipleDropdown = require '../../cmp/filters/CMultipleDropdown'
{ CLabel } = require '../../cmp/CLabel'
CIconLabel = require('../../cmp/CIconLabel').default
Utils = require '../../helpers/Utils'
{ isContentAdmin } = require '../../helpers/UserUtils'
Comparators = require '../../helpers/Comparators'

CCount = require '../../cmp/results/CCount'
CFastFilterElement = require('../../cmp/results/CFastFilterElement').default
CEditSavedSearchPopup = require('../../cmp/popups/CEditSavedSearchPopup').default
{ API } = require '../../redux/Store'
Const = require '../../helpers/Const'
An = require '../../helpers/Analytics'
CBackButton = require('../CBackButton').default
{ AccessRestriction } = require '../AccessRestriction'
{ withTranslation, Trans } = require 'react-i18next'
{ getSearchForm } = require '../../cmp/results/CFilterUtils'
{ extend } = require '../../helpers/UtilsHelpers'
{ translateCategoricalValue } = require '../../cmp/filters/CTranslateUtils'
CInlineDropdown = require('../filters/CInlineDropdown').default
Moment = require "moment"

SAVED_SEARCH_TABS = {
  ALL: 'CURRENT',
  NEW: 'NEW',
  UPDATED: 'UPDATED'
}

#Deprecated
#TODO should be replaced by CFilters
module.exports = withTranslation() Connect createReactClass(
  displayName: "CFastFilter"
  needFullReload: false

  getDeltaDateRanges: () -> [
    { title: @props.t("search.delta.last7Days"), value: 7 },
    { title: @props.t("search.delta.last30Days"), value: 30 }
  ]

  getInitialState: ()->
    searchForm: []
    isNameSaved: true
    filterError: @props.filterError || null
    version: @props.searchForm.version
    dropDownDisplay: "none"
    savedSearchTab: SAVED_SEARCH_TABS.ALL
    dateRange: @getDeltaDateRanges()[0]

  #TODO: Remove
  getFilterName: (name)->
    if @props.searchType == Const.SEARCH_TYPE_COMPANIES
      if !/[cC]ompany/.test(name) && !(name in ['hotelName', 'propertyId', 'Activity', 'portfolioSize', 'branch'])
        name = 'hotel' + name[0].toUpperCase() + name?.slice(1)
      else if !(name in ['CompanyRole'])
        name = name.replace(/company/, '')
    name

  getInitForm: (props, fullReload)->
    getSearchForm({
            searchForm: props.searchForm,
            client: props.client,
            fullReload: fullReload,
            excludedFields: [],
            onSearch: () => @props.search(0)
        })

  componentWillReceiveProps: (newProps)->
    if newProps.searchForm.filterId != @props.params.filterId || newProps.searchForm.version != @state.version
      fullReload = @needFullReload || newProps.searchForm.fullReload || (@props.params.filterId && newProps.searchForm.filterId != @props.params.filterId * 1) || newProps.searchForm.useSavedSearch
      searchForm = @getInitForm(newProps, fullReload)
      @setState
        searchForm: searchForm
        version: newProps.searchForm.version

    value = Moment().tz('UTC').subtract(@state.dateRange?.value, 'day').startOf('days').format()
    if newProps.searchForm?.deltaSearch?.value && newProps.searchForm?.deltaSearch?.value != value
      dayDifference = Moment().tz('UTC').diff(Moment(newProps.searchForm?.deltaSearch?.value), 'days')
      dateRange = @getDeltaDateRanges().find((r)=> r.value == dayDifference)
      @setState dateRange: dateRange

    if newProps.searchForm.deltaSearch?.mode && newProps.searchForm.deltaSearch?.mode != @state.savedSearchTab
      @setState savedSearchTab: newProps.searchForm.deltaSearch?.mode
      @deltaSearch(newProps.searchForm.deltaSearch?.mode, @state.dateRange)

    if newProps.filterError != @props.filterError
      @setState filterError: newProps.filterError

  componentDidMount: ()->
    document.querySelector(".main-content").addEventListener("scroll", @onScroll)
    if Utils.hasFeature(@props.client, 'DeltaSearch')
      @deltaSearch(@state.savedSearchTab, @state.dateRange)

  componentWillUnmount: ()->
    document.querySelector(".main-content").removeEventListener("scroll", @onScroll)

  getSortingLabel: ()->
    result = []
    Object.keys(@props.searchForm).filter((name) => @props.searchForm[name]).forEach (name)=>
      field = @props.searchForm[name]
      sortingNumber = if typeof(field.sortingNumber) == "number" then field.sortingNumber else field.isSortedByDefault
      if sortingNumber > -1
        if name == 'branch'
            customPropertySet = if @props.searchFieldsInfo.branch?.label then translateCategoricalValue(@props.t, "customPropertySet", @props.searchFieldsInfo.branch?.label) else @props.t('data.fast.filter.label.branch', { ns: 'data' })
            result.push(name: customPropertySet, sortingNumber: sortingNumber)
          else
            result.push(name: @props.t("data.sorting.label.#{name}", { ns: "data" }), sortingNumber: (sortingNumber))
    (result.sort((a,b)-> a.sortingNumber - b.sortingNumber).map (f)-> f.name).join(", ")

  putValue: (name, value, isResearchNeeded = true, noCountChange = false)->
    if value == "keywords"
      name = "Custom"
    @state.searchForm.get(name).value = value
    @setState searchForm: @state.searchForm
    @props.addValueToSearchForm(name, value, isResearchNeeded, noCountChange)

  putVirtualValue: (name, value, onBlur)->
    @props.addValueToSearchForm(name, value, onBlur)

  putValueFromDropDown: (name, values, options)->
    value = if values.length == 0 then null else (values.map (e)=> "'#{e}'").join(";")
    @state.searchForm.get(name).value = value
    @props.addValueToSearchForm(name, value, true, false, options)

  clear: (field)->
    v =  @state.searchForm
    @putValue(field.name, null)

  remove: (name)->
    if name == @props.t("results.fast.filter.opaque.filter.custom")
      @props.addValueToSearchForm("keywords", "", true)
    else if name == @props.t("results.fast.filter.opaque.filter.portfolio")
      @props.toggleBlockOnSearchForm("isCompanyPortfolio")
      @props.search()
    else if name == @props.t("reports.sales.management.opportunities")
      @props.initSearchForm()
      @props.search()
    @putValue(name, undefined)

  onFilterChanged: ()->
    @needFullReload = true
    document.querySelector('.search-filter').classList.remove('open')
    document.querySelector('.search-filter .slide-form').classList.remove('open');
    document.querySelector('.search-filter .slide-form').classList.remove('overflow');

  onScroll: (event) ->
    scrollPos = document.querySelector(".main-content").scrollTop
    if document.querySelector(".search-filter")
      if scrollPos > document.querySelector(".search-filter").offsetHeight+document.querySelector("header").offsetHeight
        if !document.querySelector(".search-filter").classList.contains('fixed')
          document.querySelector(".search-results")?.style.paddingTop = document.querySelector(".search-filter").offsetHeight+'px'
          document.querySelector(".search-filter").classList.add('fixed')
      if scrollPos < parseInt(document.querySelector(".search-results")?.style.paddingTop)+document.querySelector("header").offsetHeight
        document.querySelector(".search-filter").classList.remove('fixed')
        document.querySelector(".search-results").removeAttribute('style')

  onToggleSlideFilter: ()->
    document.querySelector('.search-filter').classList.toggle('open')
    document.querySelector('.search-filter').querySelector('.slide-form').classList.toggle('open')
    setTimeout(() ->
      document.querySelector('.search-filter').querySelector('.slide-form').classList.toggle('overflow')
    , 1000)

  saveFilter: (createNew, togglePopup)->
    @props.saveFilter(createNew, togglePopup)

  deleteFilter: () ->
    @props.deleteFilter()

  editSavedSearch: (createNew)->
    title = if !createNew && @props.searchForm?.name then "results.fast.filter.rename.search.name" else "results.fast.filter.save.search.name"
    @setState filterError: null
    @props.toggleEditSavedSearchPopup(()=>
      @props.saveSearchSettings(createNew)
    , title)

  setView: (view)->
    if @props.view != view
      @props.setView(view)
      if view == 'LIST'
        @props.search(0)
      else
        An.navigate.map()

  searchTypeCompanies: ()->
    An.navigate.relatedCompanies()
    @props.setSearchType(Const.SEARCH_TYPE_COMPANIES, @props.view == 'MAP')
  
  searchTypeProperties: ()->
    An.navigate.relatedProperties()
    @props.setSearchType(Const.SEARCH_TYPE_PROPERTIES, @props.view == 'MAP')

  copyLink: ()->
    @props.copyLink()

  hasFilters: (searchForm)->
    if (!searchForm)
      return
    filters = [... searchForm].filter(([name, field]) =>
      if (name == "deltaSearch")
        return false
      if field && field.value != undefined && field.name && !field.value?.isToggleable || field.value?.isToggleable && field.value?.enabled
        return true
      return false
    )
    return filters.length > 0

  deltaSearch: (tab, dateRange) ->
    since = Moment().tz('UTC').subtract(dateRange.value, 'day').startOf('days').format()
    if (tab == SAVED_SEARCH_TABS.NEW)
      @props.setDeltaSince(since)
      return
    if (tab == SAVED_SEARCH_TABS.UPDATED)
      @props.setUpdatedSince(since)
      return
    @props.removeDeltaFilters(since)

  onDateRangeSelect: (dateRange) ->
    @setState dateRange: dateRange
    @deltaSearch(@state.savedSearchTab, dateRange)

  selectTab: (tab) -> () => 
    @setState savedSearchTab: tab
    @deltaSearch(tab, @state.dateRange)

  renderTabs: ()->
    React.createElement("div", {"className": "saved-search-filters #{if @props.isCountLoading then 'loading' else ''}"},
      React.createElement("div", {"className": "saved-search-tabs"},
        React.createElement("div", {"className": "saved-search-tab #{if @state.savedSearchTab == SAVED_SEARCH_TABS.ALL then 'active' else ''}", "onClick": (@selectTab(SAVED_SEARCH_TABS.ALL))},
          React.createElement(Trans, {"i18nKey": "search.delta.records.found", "values": ({
            count: @props.result?.deltaCount?.results?.CURRENT || 0
          })},
            React.createElement("span", {"className": "saved-search-tab-count"}),
            React.createElement("span", {"className": "saved-search-tab-name"})
          )
        ),
        React.createElement("div", {"className": "saved-search-tab #{if @state.savedSearchTab == SAVED_SEARCH_TABS.NEW then 'active' else ''}", "onClick": (@selectTab(SAVED_SEARCH_TABS.NEW))},
          React.createElement(Trans, {"i18nKey": "search.delta.new.matches", "values": ({
            count: @props.result?.deltaCount?.results?.NEW || 0
          })},
            React.createElement("span", {"className": "saved-search-tab-count"}),
            React.createElement("span", {"className": "saved-search-tab-name"})
          )
        ),
        React.createElement("div", {"className": "saved-search-tab #{if @state.savedSearchTab == SAVED_SEARCH_TABS.UPDATED then 'active' else ''}", "onClick": (@selectTab(SAVED_SEARCH_TABS.UPDATED))},
          React.createElement(Trans, {"i18nKey": "search.delta.updated", "values": ({
            count: @props.result?.deltaCount?.results?.UPDATED || 0
          })},
            React.createElement("span", {"className": "saved-search-tab-count"}),
            React.createElement("span", {"className": "saved-search-tab-name"})
          )
        )
      ),
      React.createElement("div", {"className": "saved-search-date-range"},
        React.createElement(CInlineDropdown, { \
          "as": "span",  \
          "onSelect": (@onDateRangeSelect),  \
          "items": (@getDeltaDateRanges()) 
        }, """
          (""", React.createElement("span", null, (@state.dateRange.title)),
          React.createElement("i", {"className": "fa fa-caret-down small"}), """)
""")
      )
    )

  render: ()->
    nowrapIcons = [ 'zh' ].indexOf(@props.i18n.resolvedLanguage) != -1
    hasFilters = @hasFilters(@state.searchForm)
    isDeltaSearch = Utils.hasFeature(@props.client, 'DeltaSearch') && @props.isSavedSearch
    footer = (classNames)=>
      React.createElement("div", {"className": "tile-sorted #{classNames}"},
        React.createElement("div", {"className": "info-count lite", "style": (if @props.isCountLoading then opacity: 0.5)},
          (if @props.count != null && !isDeltaSearch
            React.createElement(React.Fragment, null,
              React.createElement("div", {"className": "records-found"}, React.createElement(CLabel, {"k": "results.fast.filter.records.found"})),
              React.createElement("div", {"className": "count"}, React.createElement(CCount, null)),
              (if @props.roomsCount 
                React.createElement("div", {"id": "rooms-count-info"}, " (", React.createElement(CLabel, {"k": "search.roomsCount", "param": ({ count: Utils.formatNumber(@props.roomsCount) })}), ")")
              )
            )  
          ),
          (if isDeltaSearch && @props.roomsCount
            React.createElement("div", {"id": "rooms-count-info"},
              React.createElement(CLabel, {"k": "search.roomsCount", "param": ({ count: Utils.formatNumber(@props.roomsCount) })})
            )
          )
        ),
        React.createElement("div", {"className": "box-right box-btn"},
          (if @props.searchForm.searchType == Const.SEARCH_TYPE_PROPERTIES or !@props.searchForm.searchType
            React.createElement("div", {"className": "box-nav"},
              React.createElement("a", {"className": "btn link-grey", "onClick": (@searchTypeCompanies)},
                React.createElement("i", {"className": "icon icon-related icon-middle"}), " ", React.createElement("span", {"className": "hide-mobile"}, React.createElement(CLabel, {"k": "results.fast.filter.show.related.companies"}))
              )
            )
          else if @props.searchForm.searchType == Const.SEARCH_TYPE_COMPANIES
            React.createElement("div", {"className": "box-nav"},
              React.createElement("a", {"className": "btn link-grey", "onClick": (@searchTypeProperties)},
                React.createElement("i", {"className": "icon icon-property small icon-middle"}), " ", React.createElement("span", {"className": "hide-mobile"}, React.createElement(CLabel, {"k": "results.fast.filter.show.related.properties"}))
              )
            )
          ),
          React.createElement("div", {"className": "box-nav"},
            (if @props.view == 'LIST'
              React.createElement("a", {"className": "btn link-grey overflow open-modal", "onClick": (@props.toggleSortPopup.bind(@, @props.searchForm))},
                React.createElement("i", {"className": "icon icon-middle icon-asc"}), " ", React.createElement("span", {"className": "hide-mobile"}, React.createElement(CLabel, {"k": "results.fast.filter.sorted.by", "param": ({ sortingLabel: @getSortingLabel() })})),
                React.createElement("span", {"className": "show-mobile"}, React.createElement(CLabel, {"k": "results.fast.filter.sorting"}))
              )
            )
          ),
          (if @props.mapsAvailable
            React.createElement("div", {"className": "box-nav"},
              React.createElement("span", {"className": "hide-mobile"}, React.createElement(CLabel, {"k": "results.fast.filter.views"})),
              React.createElement("a", {"onClick": (@setView.bind @, "LIST"), "className": ("btn link-grey #{if @props.view == "LIST" then "active" else ""}")}, React.createElement("i", {"className": "fa fa-align-justify"})),
              React.createElement("a", {"onClick": (@setView.bind @, "MAP"), "className": ("btn link-grey #{if @props.view == "MAP" then "active" else ""}")}, React.createElement("i", {"className": "fa fa-globe"}))
            )
          )
        )
      )

    React.createElement("div", {"className": "container-center", "key": (@props.params.filterId)},
      (if @props.editSavedSearchPopup
        React.createElement(CEditSavedSearchPopup, { \
          "onSave": (@props.editSavedSearchPopup.data?.onSave),  \
          "onChange": (@props.setFilterName),  \
          "value": (@props.searchForm?.name || ""),  \
          "createdById": (@props.searchForm?.createdById),  \
          "createdByName": (@props.searchForm?.createdByName),  \
          "filterId": (@props.searchForm?.filterId || false),  \
          "isShared": (@props.searchForm?.isShared || false),  \
          "isLink": (@props.searchForm?.isLink || false),  \
          "weeklyDigest": (@props.searchForm?.weeklyDigest || false),  \
          "weeklyDigestShared": (@props.searchForm?.weeklyDigestShared || false),  \
          "error": (@state.filterError),  \
          "title": (@props.editSavedSearchPopup.data?.title)
        })
      ),
      React.createElement("div", {"className": "slide-form"},
        React.createElement("div", {"className": "search-title hide-mobile"},
          React.createElement("div", {"className": "box-back", "style": (marginRight: '15px', flexBasis: if @props.searchForm?.name then '105px' else 'auto')},
            React.createElement(CBackButton, {"defaultPath": "/search"})
          ),
          React.createElement("div", {"className": "search-name box-input"},
            (if @props.searchForm?.filterId && @props.searchForm?.name
              React.createElement(React.Fragment, null,
                React.createElement("span", {"onClick": (() => if @props.user?.id == @props.searchForm?.createdById || isContentAdmin(@props.user, @props.isAliasEnabled) then @editSavedSearch(false)), "className": "name-block #{if !@props.isNameSaved then 'red' else ''}", "style": (fontSize: '28px', lineHeight: '28px', verticalAlign: 'middle', cursor: 'pointer', overflowWrap:'anywhere')},
                  (if @props.isDigest then @props.t("results.fast.filter.searh.name.digest", {name: @props.searchForm?.name}) else @props.searchForm?.name)
                ),
                (if @props.searchForm.isShared
                  React.createElement("span", null,
                    (if @props.searchForm.createdById != @props.user?.id
                      React.createElement("span", null, " (", React.createElement(CLabel, {"className": "nowrap", "k": "results.fast.filter.save.saved.search.shared.by"}), " ", (@props.searchForm.createdByName), ")")
                    else
                      React.createElement("span", null, " (", React.createElement(CLabel, {"className": "nowrap", "k": "results.fast.filter.save.saved.search.shared.label"}), ")")
                    )
                  )
                ),
                (if !@props.isNameSaved
                  React.createElement("div", {"className": "message-error"}, React.createElement(CLabel, {"k": "results.fast.filter.searh.name.not.saved"}))
                )
              )
            )
          ),
          React.createElement("div", {"className": "search-menu"},
            (if @props.isDeleteButtonNeeded()
              React.createElement(CIconLabel, {"className": "btn menu-item link-grey", "onClick": (@deleteFilter), "icon": "fa-close", "k": "results.fast.filter.delete"})
            ),
            (if @props.isSaveAsNewButtonNeeded()
              React.createElement(AccessRestriction, null,
                React.createElement(CIconLabel, {"className": "btn menu-item link-grey", "onClick": (@editSavedSearch.bind @, true), "nowrap": (nowrapIcons), "icon": "fa-save", "k": "results.fast.filter.save.new.saved.search.save"})
              )
            ),
            (if @props.isSaveButtonNeeded()
              React.createElement(CIconLabel, {"className": "btn menu-item link-grey", "onClick": (@saveFilter.bind(@, false, false)), "nowrap": (nowrapIcons), "icon": "fa-repeat", "k": "results.fast.filter.save.changes"})
            ),
            (if @props.view == 'LIST' && @props.searchType != Const.SEARCH_TYPE_COMPANIES
              React.createElement(CIconLabel, {"className": "btn menu-item link-grey", "onClick": (@props.export), "icon": "fa-clipboard", "k": "results.fast.filter.export"})
            ),
            React.createElement(CIconLabel, {"className": "btn menu-item link-grey", "onClick": (@copyLink), "icon": "fa-copy", "k": "property.copy.deep.link"})
          )
        ),

        React.createElement("div", {"className": "block-tile search-fast-filter"},
          React.createElement("div", {"className": "box-tile"},
            (if hasFilters && !@props.savedSearchLoading
              React.createElement("div", {"className": "tile-group"},
                React.createElement("form", {"className": "form form-small"},
                  React.createElement("div", {"className": "filters-wrapper"},
                    ([...@state.searchForm].map ([name, field])=>
                      if field && name != 'deltaSearch' && field.value != undefined && field.name && !field.value?.isToggleable || field.value?.isToggleable && field.value?.enabled
                        activityProjectSearch = !@props.searchForm["showNewConstruction"] && !@props.searchForm["showRenovations"] && !@props.searchForm["showConversions"]
                        React.createElement(CFastFilterElement, { \
                          "key": (name),  \
                          "name": (name),  \
                          "displayName": (@getFilterName(name)),  \
                          "field": (field),  \
                          "disabled": (activityProjectSearch && field.name == "hasTimelineFilter" ),  \
                          "remove": (@remove.bind @, name),  \
                          "clear": (@clear.bind @, field),  \
                          "putValue": (@putValue),  \
                          "putValueFromDropDown": (@putValueFromDropDown),  \
                          "fieldInfo": (@props.searchFieldsInfo?[name] || @props.searchCompanyFieldsInfo?[name])
                        })
                    )
                  ),
                  React.createElement("div", {"className": "box-btn box-right box-btn-more hide-mobile"},
                    React.createElement("a", {"className": "btn btn-red btn-normal open-modal", "onClick": (@props.toggleFilterPopup.bind @, @onFilterChanged, extend({}, @props.searchForm))}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "results.fast.filter.more.filters"})))
                  )
                )
              )
            ),
            React.createElement("div", {"className": "tile-group tile-group-flex #{if hasFilters then 'show-mobile'}"},
              React.createElement("div", {"className": "show-mobile box-btn-more text-right"},
                React.createElement("a", {"className": "btn btn-red btn-normal open-modal", "onClick": (@props.toggleFilterPopup.bind @, @onFilterChanged, extend({}, @props.searchForm))}, React.createElement("span", {"className": "upper"}, (@props.t("results.fast.filter.more.filters"))))
              ),
              (footer('show-mobile')),
              (if !hasFilters
                footer('hide-mobile')
              ),
              (if !hasFilters
                React.createElement("form", {"className": "form form-small"},
                  React.createElement("div", {"className": "box-btn box-right box-btn-more hide-mobile"},
                    React.createElement("a", {"className": "btn btn-red btn-normal open-modal", "onClick": (@props.toggleFilterPopup.bind @, @onFilterChanged, extend({}, @props.searchForm))}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "results.fast.filter.more.filters"})))
                  )
                )
              )
            ),
            (if @props.isSavedSearch && Utils.hasFeature(@props.client, 'DeltaSearch')
              @renderTabs()
            ),
            (if hasFilters
              footer(if isDeltaSearch then 'tile-bottom no-border hide-mobile' else 'tile-bottom hide-mobile')
            )
          )
        )
      ),
      React.createElement("div", {"className": "box-controls", "onClick": (@onToggleSlideFilter)},
        React.createElement("a", {"className": "btn btn-slide-filter show"}, React.createElement(CLabel, {"k": "results.fast.filter.show.controls"}), " ", React.createElement("i", {"className": "fa fa-angle-down"})),
        React.createElement("a", {"className": "btn btn-slide-filter hide"}, React.createElement(CLabel, {"k": "results.fast.filter.hide.controls"}), " ", React.createElement("i", {"className": "fa fa-angle-up"}))
      )
    )
), {
  dispatch: (dispatch) ->
    toggleSortPopup: (form) -> dispatch(type: 'TOGGLE_POPUP_SORT', data: form)
    toggleBlockOnSearchForm: (name) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name)
    toggleEditSavedSearchPopup: (onSave, title) -> dispatch(type: 'TOGGLE_POPUP_EDIT_SAVED_SEARCH', data: onSave: onSave, title: title)
    toggleFilterPopup: (onSearchCallback, form) -> dispatch(type: 'TOGGLE_POPUP_FILTER', onSuccessCallback: onSearchCallback, data: originalForm: form)
    setExcludedProperties: (ids) ->
      dispatch(type: 'SET_EXCLUDED_PROPERTIES', ids: ids)

    clearExcludedProperties: () -> dispatch(type: 'REMOVE_ALL_EXCLUDED_PROPERTIES')
    setBlocksOnSearchForm: (blocks) ->
      dispatch(type: 'SET_BLOCKS_ON_SEARCH_FORM', blocks: blocks)
      dispatch(type: 'NEW_SEARCH')

    addValueToSearchForm: (name, value, isReserchNeeded, noCountChange, options) ->
      if isReserchNeeded
        dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM_AND_SEARCH', name: name, value: value, options: options)
      else
        dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, noCountChange: noCountChange, options: options)
    setSearchType: (searchType, needMapSearch) -> dispatch(type: 'SET_SEARCH_TYPE', searchType: searchType, needSearch: true, needMapSearch: needMapSearch)
    setView: (view) -> dispatch(type: 'SET_VIEW', view: view)
    search: (page) -> dispatch(type: 'SEARCH', page: page, target:"CFastFilter")
    setActiveCompany: (activeCompany) -> dispatch(type: 'SET_ACTIVE_COMPANY', activeCompany: activeCompany)
    initSearchForm: () -> dispatch(type: 'INIT_SEARCH_FORM')
    setDeltaSince: (value) -> dispatch(type: 'SET_DELTA_SINCE', value: value)
    setUpdatedSince: (value) -> dispatch(type: 'SET_UPDATED_SINCE', value: value)
    removeDeltaFilters: (value) -> dispatch(type: 'REMOVE_DELTA_FILTERS', value: value)

  state: (state) ->
    mapsAvailable: state.session?.user?.client?.maps
    activeCompany: state.searchForm.activeCompany
    editSavedSearchPopup: state.popup?.TOGGLE_POPUP_EDIT_SAVED_SEARCH
    searchFieldsInfo: state.app.searchFieldsInfo
    searchCompanyFieldsInfo: state.app.searchCompanyFieldsInfo
    searchForm: state.searchForm.form
    view: state.searchForm.form?.activeView
    searchType: state.searchForm.form?.searchType
    result: state.searchForm?.result
    info: state.searchForm?.info
    translation: state.translation
    client: state.session?.user?.client
    user: state.session?.user
    isAliasEnabled: state.session?.isAliasEnabled
    roomsCount: state.searchForm.info?.roomsCount
    count: state.searchForm.info?.count
    isCountLoading: state.searchForm?.info?.isCountLoading
}
