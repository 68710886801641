import { debounce } from "throttle-debounce"
import '../../assets/style/action-btn.scss'
import '../../assets/style/borders.scss'
import { CLabel } from '../cmp/CLabel'
import CThreeDots from '../cmp/CThreeDots'
import CInlineDropdown from '../cmp/filters/CInlineDropdown'
import CNotesExportPopup from '../cmp/popups/CNotesExportPopup'
import { cleanupFilter, getSearchForm } from '../cmp/results/CFilterUtils'
import An from '../helpers/Analytics'
import Connect from '../helpers/Connect'
import Utils from '../helpers/Utils'
import Header from '../pages/parts/Header'
import Menu from '../pages/parts/Menu'
import { API } from '../redux/Store'
import './actions_and_notes.scss'
import CNoteTabContent from './CNoteTabContent'
import CSearchFilter from './CSearchFilter'
import CDateRange from "../cmp/filters/CDateRange/CDateRange";
import { withTranslation, Trans } from 'react-i18next'
import { translateANPCCounter } from '../cmp/filters/CTranslateUtils'


const DUE_FILTER_TAB = "ACTION_DUE"
const ALL_FILTER_TAB = "ALL_NOTES"

const ALL_NOTES = "ALL_NOTES"
const PROPERTY_NOTES = "PROPERTY_NOTES"
const COMPANY_NOTES = "COMPANY_NOTES"

const DUE_ACTIONS = "DUE_ACTIONS"
const DONE_ACTIONS = "DONE_ACTIONS"

class ActionsAndNotes2 extends Component {

    xhrLoadData = null;
    xhrLoadStats = null;


    constructor(props) {
        super(props)

        const createdByList = this.getCreatedByDefault(props.user);
        const assignedToList = this.getAssignedToDefault(props.user);
        this.props.setSearchType(Const.SEARCH_TYPE_PROPERTIES);

        const savedState = JSON.parse(sessionStorage.getItem(Const.ACTIONS_AND_NOTES_FILTER_STATE)) || {};
        if (savedState && savedState.filterVisual && savedState.filterVisual.parentType) {
            const parentType = this.getParentTypesList().find((type) => {
                return savedState.filterVisual.parentType.every(value => type.value.includes(value)) && savedState.filterVisual.parentType.length === type.value.length;
            }) || 0;
            savedState.filterVisual.parentType = parentType;
        }

        this.state = {
            showExportDialog: false,
            filterVisual: {
                parentType: this.getParentTypesList()[0],
                createdById: props.hasAccessToAssignableActions ? createdByList[1] : createdByList[0],
                assignedToId: props.hasAccessToAssignableActions ? assignedToList[0] : {},
                useSavedSearch: undefined,
            },
            filter: {
                isToggleable: true,
                enabled: true,
                assignedToId: props.hasAccessToAssignableActions ? [props.user && props.user.id] : undefined,
                createdById: props.hasAccessToAssignableActions ? [] : [props.user && props.user.id],
                type: ["ACTION"],
                parentType: this.getParentTypesList()[0].value,
                updatedAfter: null,
                updatedBefore: null,
                isDeleted: false,
                text: null,
                dueTo: null,
                isNotDone: true
            },

            stats: {
                ALL_NOTES: this.getDefaultStatsStateValue(),
                DUE_ACTIONS: this.getDefaultStatsStateValue(),
                DONE_ACTIONS: this.getDefaultStatsStateValue(),
                PROPERTY_NOTES: this.getDefaultNotesStateValue(),
                COMPANY_NOTES: this.getDefaultNotesStateValue(),
            },

            notes: {
                ALL_NOTES: this.getDefaultNotesStateValue(),
                DUE_ACTIONS: this.getDefaultNotesStateValue(),
                DONE_ACTIONS: this.getDefaultNotesStateValue(),
                PROPERTY_NOTES: this.getDefaultNotesStateValue(),
                COMPANY_NOTES: this.getDefaultNotesStateValue(),
            },

            page: 0,
            notesLoading: true,
            showLoader: true,
            authorsLoading: false,
            statsLoading: true,
            initialStatsLoading: true,
            currentTab: DUE_FILTER_TAB,
            createdByList: createdByList,
            assignedToList: assignedToList,
            isMobile: Utils.isMobile(),
            height: 'auto',
            isDropdownOpen: false,
            animation: this.getDefaultAnimationState(),
            ...savedState
        }
    }

    getDefaultNotesStateValue = () => {
        return {
            results: [],
            count: 0,
            totalCount: 0
        }
    }

    getDefaultStatsStateValue = () => {
        return {
            actionsCount: 0,
            notesCount: 0,
            propertiesCount: 0,
            companiesCount: 0,
            propertiesFilterCount: 0,
            companiesFilterCount: 0
        }
    }

    getDefaultAnimationStateValue = () => {
        return {
            onAnimationEnd: () => { },
            isCollapsing: false,
            isExpanding: false,
            visible: true,
            delay: 0
        }
    }

    getDefaultAnimationState = () => {
        return {
            noteId: null,
            DUE_ACTIONS: this.getDefaultAnimationStateValue(),
            DONE_ACTIONS: this.getDefaultAnimationStateValue(),
            ALL_NOTES: this.getDefaultAnimationStateValue(),
            PROPERTY_NOTES: this.getDefaultAnimationStateValue(),
            COMPANY_NOTES: this.getDefaultAnimationStateValue()
        }
    }

    getExportableTypes = (propertiesDisabled, companiesDisabled) => {
        return [
            { title: this.props.t("actions.and.notes.exportable.properties"), disabled: propertiesDisabled, value: { type: Const.SEARCH_TYPE_PROPERTIES, field: 'propertyNotesFilter' } },
            { title: this.props.t("actions.and.notes.exportable.companies"), disabled: companiesDisabled, value: { type: Const.SEARCH_TYPE_COMPANIES, field: 'companyNotesFilter' } }
        ];
    }

    getParentTypesList = () => {
        return [
            {
                title: this.props.t("actions.and.notes.filter.parent.any.item"),
                item: <CLabel k="actions.and.notes.filter.parent.property.and.company" />,
                value: ['PROPERTY', 'COMPANY']
            },
            {
                title: this.props.t("actions.and.notes.filter.parent.property"),
                item: <CLabel k="actions.and.notes.filter.parent.property" />,
                value: ['PROPERTY']
            },
            {
                title: this.props.t("actions.and.notes.filter.parent.company"),
                item: <CLabel k="actions.and.notes.filter.parent.company" />,
                value: ['COMPANY']
            },
        ];
    }

    getCreatedByDefault = (user) => [
        { titleKey: "actions.and.notes.filter.written.by.me", value: [user && user.id] },
        { titleKey: "actions.and.notes.filter.written.by.anyone", value: [] }
    ]

    getAssignedToDefault = (user) => [
        { titleKey: "actions.and.notes.filter.written.by.me", value: [user && user.id] },
        { titleKey: "actions.and.notes.filter.written.by.anyone", value: [] }
    ]

    getShowValues = () => [
        {
            value: true,
            title: this.props.t("actions.and.notes.filter.dueOnly")
        },
        {
            value: false,
            title: this.props.t("actions.and.notes.filter.doneOnly")
        },
        {
            value: null,
            title: this.props.t("actions.and.notes.filter.dueDone")
        }
    ]

    getSavedFilters = () => {

        const sharedTitle = this.props.t("actions.and.notes.filter.search.saved.shared")
        const ownTitle = this.props.t("actions.and.notes.filter.search.saved.own")
        const noneTitle = this.props.t("actions.and.notes.filter.search.saved.none")


        const sharedList = [
            { title: sharedTitle + (this.props.sharedFilters.length === 0 ? (" \u2013 " + noneTitle) : ""), inactive: true },
            ... this.props.sharedFilters.map((f) => ({
                title: f.name, value: f
            })),
        ]

        sharedList[sharedList.length - 1].divider = true

        return [
            ...sharedList,
            { title: ownTitle + (this.props.savedFilters.length === 0 ? (" \u2013 " + noneTitle) : ""), inactive: true },
            ... this.props.savedFilters.map((f) => ({
                title: f.name, value: f
            }))
        ]
    }

    componentDidMount = () => {
        An.main.actions()
        this.loadData(this.state.filter, this.state.page);
        this.loadAuthors()
        this.loadAssignees()
        if (this.props.searchForm.storageKey === "LENAV_PREVIOUS_SEARCH_FORM") {
            this.clearFilters();
        }
        document.querySelector(".main-content").addEventListener("scroll", this.onScroll)
        document.body.addEventListener("click", this.handleOutsideClick);
    }

    handleOutsideClick = (e) => {
        if (this.state.isDropdownOpen && !e.target.closest(".show-delete-dropdown")) {
            this.setState({ isDropdownOpen: false })
        }
    }

    saveState = () => {
        const state = {
            filterVisual: {
                ...this.state.filterVisual,
                parentType: this.state.filterVisual.parentType.value
            },
            filter: this.state.filter,
            currentTab: this.state.currentTab,
        };
        sessionStorage.setItem(Const.ACTIONS_AND_NOTES_FILTER_STATE, JSON.stringify(state));
    }

    componentWillUnmount = () => {
        document.querySelector(".main-content").removeEventListener("scroll", this.onScroll)
        document.body.removeEventListener("click", this.handleOutsideClick);
    }

    getHighestTotalCount = () => {
        const columns = this.getColumns();
        const totalCounts = columns.map((column) => {
            return this.state.notes[column].totalCount;
        });
        return Math.max(...totalCounts);
    }

    hasResults = () => {
        const columns = this.getColumns();
        const resultsCount = columns.map((column) => {
            return this.state.notes[column].results.length;
        });
        return Math.max(...resultsCount) > 0;
    }

    onScroll = (event) => {
        const scrollPosition = document.querySelector(".main-content").scrollTop
        const tileHeight = document.querySelector(".dashboard .block-tile").clientHeight
        const mainContentHeight = tileHeight - document.querySelector(".main-content").clientHeight
        const scrollHeight = tileHeight - mainContentHeight
        const searchPoint = scrollHeight - 500
        const locked = this.state.notesLoading || this.state.statsLoading
        const totalCount = this.getHighestTotalCount();
        if (this.state.page < (totalCount / 20 - 1) && !locked && scrollPosition > searchPoint)
            this.setState({ page: this.state.page + 1, height: scrollHeight },
                () => {
                    this.loadData(this.state.filter, this.state.page)
                })
    }

    getColumns = () => {
        const isActions = this.state.currentTab === DUE_FILTER_TAB;
        if (isActions) {
            return [DUE_ACTIONS, DONE_ACTIONS];
        }
        if (this.props.hasAccessToAssignableActions) {
            return [PROPERTY_NOTES, COMPANY_NOTES];
        }
        return [ALL_NOTES];
    }

    getParentTypeValue = (column) => {
        if (column === PROPERTY_NOTES) {
            return ["PROPERTY"];
        }
        if (column === COMPANY_NOTES) {
            return ["COMPANY"];
        }

        return this.state.filter.parentType;
    }

    getIsNotDoneValue = (column) => {
        if (column === DUE_ACTIONS) {
            return true;
        }
        if (column === DONE_ACTIONS) {
            return false;
        }
        return null;
    }

    setActiveTab = (value) => {
        if (this.state.currentTab === value) {
            return;
        }
        const noteType = value == DUE_FILTER_TAB ? ["ACTION"] : ["ACTION", "NOTE"];
        const isNotDone = value == DUE_FILTER_TAB ? true : null;
        this.setState({
            currentTab: value, initialStatsLoading: true, animation: this.getDefaultAnimationState(), filterVisual: {
            ...this.state.filterVisual,
        } })
        this.setFilterValue({
            type: noteType,
            isNotDone: isNotDone,
        });
    }

    gotoSearch = (type) => {
        this.props.setSearchType(type.type)

        this.getExportableTypes().forEach((item) => {
            this.props.addValueToForm(item.value.field, undefined);
        })
        this.props.addValueToForm(type.field, this.state.filter);
        this.props.setStorageKey("LENAV_PREVIOUS_SEARCH_FORM");
        this.props.router.push('/results');
    }

    toggleExportNotes = () => {
        this.setState({ showExportDialog: !this.state.showExportDialog })
    }

    setContainsText = (v) => {
        this.setFilterValue({ text: v.target.value })
    }

    setCreatedBy = (v) => {
        this.setState({ filterVisual: { ...this.state.filterVisual, createdById: v } })
        this.setFilterValue({ createdById: v.value })
    }

    setAssignedTo = (v) => {
        this.setState({ filterVisual: { ...this.state.filterVisual, assignedToId: v } })
        this.setFilterValue({ assignedToId: v.value })
    }

    setParentType = (v) => {
        this.state.filterVisual.parentType = v
        this.setState(this.state)
        this.setFilterValue({ parentType: v.value })
    }

    processFilter = (filter) => {
        const isDueFilterTab = this.state.currentTab == DUE_FILTER_TAB ? true : false;
        if (isDueFilterTab && this.props.hasAccessToAssignableActions) {
            return filter;
        }

        return {
            ...filter,
            assignedToId: undefined
        }
    }

    setFilterValue = (value) => {
        this.setState({ filter: { ...this.state.filter, ...value }, page: 0 }, () => {
            const useDebounce = value.text !== undefined;
            this.reloadData(this.state.filterVisual.useSavedSearch, useDebounce);
            this.saveState();
            return this.state;
        })
    }

    onExportNext = (params) => {
        params.searchForm = this.props.searchForm;
        params.filterNotes = this.state.filter;

        this.setState({ showExportDialog: !this.state.showExportDialog }, () => {
            this.props.checkWsConnection()
            this.props.toggleRenameExportFilename(params)
        });
    }

    getDoneAt = (column) => {
        const hasDateFilter = this.state.filter.updatedAfter || this.state.filter.updatedBefore;
        if (column === DONE_ACTIONS && !hasDateFilter) {
            return {
                daysAgo: 90,
                enabled: true,
                mode: "DAYS"
            }
        }
        return undefined;
    }

    loadColumn = (filter, page) => (column) => {
        const columnFilter = this.processFilter({
            ...filter,
            isNotDone: this.getIsNotDoneValue(column),
            parentType: this.getParentTypeValue(column),
            doneAt: this.getDoneAt(column),
            isDeleted: [PROPERTY_NOTES, COMPANY_NOTES, ALL_NOTES].includes(column) ? filter.isDeleted : false
        });

        const loadStats = () => {
            return new Promise((resolve) => {
                API.notesStats(columnFilter, this.props.searchForm, (data) => {
                    this.setState({
                        stats: {
                            ...this.state.stats,
                            [column]: data,
                        }
                    });
                    resolve(data);
                })
            })
        }

        const loadResults = () => {
            return new Promise((resolve) => {
                API.notesList(columnFilter, this.props.searchForm, page, (data) => {
                    resolve({
                        results: data.results,
                        totalCount: data.totalCount,
                        count: data.count
                    });
                })
            });
        }

        const promises = [loadResults()];

        if (page === 0) {
            promises.push(loadStats());
        }

        return new Promise((resolve) => {
            Promise.all(promises).then(([notes, stats]) => {
                resolve({
                    key: column,
                    stats,
                    notes
                })
            });
        });
    }

    loadColumns = (filter, page, columns) => {
        if (page === 0) {
            this.loadAssignees();
        }


        const promises = columns.map(this.loadColumn(filter, page));

        return new Promise((resolve) => {
            Promise.all(promises).then((columns) => {
                resolve(columns);
            });
        });
    }

    loadData = (filter, page) => {
        this.setState({ notesLoading: true, showLoader: true, statsLoading: page === 0 })
        const notes = {
            ...this.state.notes
        };
        const stats = {
            ...this.state.stats
        };
        this.loadColumns(filter, page, this.getColumns()).then((columns) => {
            columns.forEach((column) => {
                const results = page === 0 ? column.notes.results : [
                    ...this.state.notes[column.key].results,
                    ...column.notes.results
                ];
                notes[column.key] = {
                    ...column.notes,
                    results,
                };
                if (column.stats) {
                    stats[column.key] = column.stats;
                }
            });
            this.setState({
                notes,
                stats,
                statsLoading: false,
                initialStatsLoading: false,
                showLoader: false,
                notesLoading: false
            });
        });
    }

    reloadNotes = (filter, page, cols) => {
        const promises = [];
        const columns = cols || this.getColumns();
        this.setState({ notesLoading: true });

        const loadPage = (currentPage) => {
            return this.loadColumns(filter, currentPage, columns);
        }

        for (let currentPage = 0; currentPage <= page; currentPage++) {
            promises.push(loadPage(currentPage));
        }



        return new Promise((resolve) => {
            Promise.all(promises).then((data) => {
                const notes = {
                    ...this.state.notes
                };

                const stats = {
                    ...this.state.stats
                };

                columns.forEach((column) => {
                    notes[column] = undefined;
                    stats[column] = undefined;
                });

                data.forEach((columns) => {
                    columns.forEach((column) => {
                        const results = !Boolean(notes[column.key]) ? column.notes.results : [
                            ...notes[column.key].results,
                            ...column.notes.results
                        ];
                        notes[column.key] = {
                            ...column.notes,
                            results,
                        };
                        if (column.stats) {
                            stats[column.key] = column.stats;
                        }
                    });
                })

                resolve({ notes, stats });
            });
        });
    }

    loadAuthors = () => {
        this.setState({ authorsLoading: true })
        API.notesDictionaryAuthors((data) => {
            const otherAuthors = data
                .filter((it) => it.value !== this.props.user.id)
                .map((it) => ({
                    title: it.label,
                    value: [it.value]
                }))

            const createdByList = [...this.getCreatedByDefault(this.props.user), ...otherAuthors]
            this.setState({ authorsLoading: false, createdByList: createdByList })
        })
    }

    loadAssignees = () => {
        this.setState({ authorsLoading: true })
        API.notesDictionaryAssignees((data) => {
            const others = data
                .filter((it) => it.value !== this.props.user.id)
                .map((it) => ({
                    title: it.label,
                    value: [it.value]
                }))

            const items = [...this.getAssignedToDefault(this.props.user), ...others]
            this.setState({ authorsLoading: false, assignedToList: items })
        })
    }

    loadFilter = (item) => {
        if (item.value) {
            this.props.setForm(cleanupFilter(item.value))
            this.reloadData(item.value)
        }
    }

    onFilterPopupSearch = () => {
        if (this.props.searchForm?.propertyNotesFilter?.value) {
            this.setFilterValue(this.props.searchForm?.propertyNotesFilter?.value)
        }
        this.props.setForm(cleanupFilter(this.props.searchForm))
        this.reloadData();
    };

    loadDataDebounce = debounce(400, (filter, page) => {
        this.loadData(filter, page);
    })

    reloadData = (useSavedSearch, useDebounce) => {
        this.setState({ page: 0, filterVisual: { ... this.state.filterVisual, useSavedSearch }, animation: this.getDefaultAnimationState(), }, (s, p) => {
            if (useDebounce) {
                this.loadDataDebounce(this.state.filter, this.state.page);
                return;
            }
            this.loadData(this.state.filter, this.state.page);
            return this.state;
        })
    }

    onExpandingEnd = (column) => {
        const collapsingColumn = column === DONE_ACTIONS ? DUE_ACTIONS : DONE_ACTIONS;
        this.setState({
            animation: {
                ...this.state.animation,
                [column]: {
                    ...this.state.animation[column],
                    isExpanding: false,
                    isCollapsing: false,
                    visible: true,
                },
                [collapsingColumn]: {
                    ...this.state.animation[collapsingColumn],
                    isExpanding: false,
                    isCollapsing: true,
                    delay: 1000,
                    visible: false,
                    onAnimationEnd: this.onCollapsingEnd
                }
            }
        });
    }

    onCollapsingEnd = (column) => {
        this.state.animation[column].reloaderPromise?.then(({ notes, stats }) => {
            this.setState({
                notes,
                stats,
                statsLoading: false,
                initialStatsLoading: false,
                notesLoading: false,
                animation: this.getDefaultAnimationState()
            });
        })
    }

    animateMoveBetweenColumns = (column, noteId) => {
        const collapsingColumn = column;
        const expandingColumn = collapsingColumn === DONE_ACTIONS ? DUE_ACTIONS : DONE_ACTIONS;
        this.setState({
            animation: {
                ...this.state.animation,
                noteId,
                [expandingColumn]: {
                    visible: false,
                    isExpanding: true,
                    isCollapsing: false,
                    onAnimationEnd: this.onExpandingEnd
                }
            }
        });

        this.reloadNotes(this.state.filter, this.state.page, [expandingColumn]).then(({ notes, stats }) => {
            this.setState({
                notes,
                stats,
                statsLoading: false,
                initialStatsLoading: false,
                notesLoading: false,
                animation: {
                    ...this.state.animation,
                    [column]: {
                        ...this.state.animation[column],
                        reloaderPromise: this.reloadNotes(this.state.filter, this.state.page, [column])
                    }
                }
            });

            const note = notes[expandingColumn].results.find(
              (note) => note.id === noteId
            );

            if (!note) {
              this.onExpandingEnd(expandingColumn);
            }
        });
    }

    onMarkingAction = (noteId) => {
        if (!this.props.hasAccessToAssignableActions || this.state.currentTab === ALL_FILTER_TAB) {
            return;
        }
        this.setState({
            animation: {
                ...this.state.animation,
                noteId
            }
        })
    }

    onNoteChanged = (column) => (response) => {
        const notes = {
            ...this.state.notes,
            [column]: {
                ...this.state.notes[column],
                results: [
                    ...this.state.notes[column].results.map((note) => {
                        if (note.id !== response.id) {
                            return note;
                        }
                        return response;
                    })
                ]
            }
        }


        const oldNote = this.state.notes[column].results.find((note) => {
            return note.id === response.id;
        });

        const changedActive = oldNote.active !== response.active && oldNote.type === response.type;

        if (changedActive && this.state.currentTab === DUE_FILTER_TAB && this.props.hasAccessToAssignableActions) {
            this.animateMoveBetweenColumns(column, response.id);
            return;
        }

        this.setState({ notes });

        this.reloadNotes(this.state.filter, this.state.page, [column]).then(({ notes, stats }) => {
            this.setState({
                notes,
                stats,
                statsLoading: false,
                initialStatsLoading: false,
                notesLoading: false,
            });
        });
    }

    onNoteAdded = (note) => {
        if (this.state.currentTab === DUE_FILTER_TAB) {
            if (note.type !== "ACTION") {
                return;
            }
            this.reloadNotes(this.state.filter, this.state.page, [DUE_ACTIONS]).then(({ notes, stats }) => {
                this.setState({
                    notes,
                    stats,
                    statsLoading: false,
                    initialStatsLoading: false,
                    notesLoading: false,
                });
            });
            return;
        }

        this.reloadNotes(this.state.filter, this.state.page, [PROPERTY_NOTES, COMPANY_NOTES]).then(({ notes, stats }) => {
            this.setState({
                notes,
                stats,
                statsLoading: false,
                initialStatsLoading: false,
                notesLoading: false,
            });
        });
    }

    onNoteDeleted = (column) => (id) => {
        const notes = {
            ...this.state.notes,
            [column]: {
                ...this.state.notes[column],
                results: [
                    ...this.state.notes[column].results.filter((note) => {
                        return note.id !== id;
                    })
                ]
            }
        }

        this.setState({ notes });
        this.reloadNotes(this.state.filter, this.state.page, [column]).then(({ notes, stats }) => {
            this.setState({
                notes,
                stats,
                statsLoading: false,
                initialStatsLoading: false,
                notesLoading: false,
            });
        });
    }

    showFilters = () => {
        this.props.toggleFilterPopup(this.props.searchForm, this.onFilterPopupSearch)
    }

    clearFilters = () => {
        this.props.initSearchForm()
        this.reloadData()
    }

    setUpdatedDate = (value) => {
        this.setFilterValue({
            updatedBefore: value.updatedBefore,
            updatedAfter: value.updatedAfter,
            controlValue: value.controlValue
        });
    }
    renderColumnTitle = (column) => {
        if (!this.props.hasAccessToAssignableActions) {
            return null;
        }
        const hasDateFilter = this.state.filter.updatedAfter || this.state.filter.updatedBefore;
        if (column === DUE_ACTIONS) {
            const hasResults = this.state.notes[column].totalCount > 0;
            return <div className={`actions-columns-header column-due-actions ${!hasResults ? 'no-results' : ''}`}>
                <div className="actions-columns-title">
                    <span className="bold">
                        <CLabel k="actions.and.notes.tab.column.due" />
                    </span>
                    <span className="actions-columns-count">
                        ({this.props.t("an.actions", {
                            count: this.state.notes[column].totalCount
                        })})
                    </span>
                </div>
                <div className="aan-tab-label">
                    <CLabel k="actions.and.notes.tab.sort.by.date.to" />
                </div>
            </div>;
        }
        if (column === DONE_ACTIONS) {
            const hasResults = this.state.notes[column].totalCount > 0;
            return <div className={`actions-columns-header column-done-actions ${!hasResults ? 'no-results' : ''}`}>
                <div className="actions-columns-title">
                    <span className="bold">
                        <CLabel k="actions.and.notes.tab.column.done" />
                    </span>
                    <span className="actions-columns-count">
                        ({this.props.t(hasDateFilter ? "an.actions" : "an.actionsIn90Days", {
                            count: this.state.notes[column].totalCount
                        })})
                    </span>
                </div>
                <div className="aan-tab-label">
                    <CLabel k="actions.and.notes.tab.sort.by.completed" />
                </div>
            </div>;
        }
        if (column === PROPERTY_NOTES) {
            const hasResults = this.state.notes[column].totalCount > 0;
            return <div className={`actions-columns-header column-properties ${!hasResults ? 'no-results' : ''}`}>
                <div className="actions-columns-title">
                    <span className="bold">
                        <CLabel k="actions.and.notes.tab.column.properties" />
                    </span>
                </div>
                <div className="aan-tab-label">
                    {this.props.t("an.actions", {
                        count: this.state.stats[column].actionsCount
                    })}, {this.props.t("an.notes", {
                        count: this.state.stats[column].notesCount
                    })}
                </div>
            </div>;
        }
        if (column === COMPANY_NOTES) {
            const hasResults = this.state.notes[column].totalCount > 0;
            return <div className={`actions-columns-header column-companies ${!hasResults ? 'no-results' : ''}`}>
                <div className="actions-columns-title">
                    <span className="bold">
                        <CLabel k="actions.and.notes.tab.column.companies" />
                    </span>
                </div>
                <div className="aan-tab-label">
                    {this.props.t("an.actions", {
                        count: this.state.stats[column].actionsCount
                    })}, {this.props.t("an.notes", {
                        count: this.state.stats[column].notesCount
                    })}
                </div>
            </div>;
        }
        return null;
    }

    toggleShowDeleted = () => {
        this.setFilterValue({ isDeleted: this.state.filter.isDeleted === null ? false : null });
    }

    renderNoteTabContent = (tab, column, fields) => {
        return <CNoteTabContent
            title={this.renderColumnTitle(column)}
            animation={this.state.animation[column]}
            animatingNoteId={this.state.animation.noteId}
            onMarkingAction={this.onMarkingAction}
            notesLoading={this.state.notesLoading}
            isDimmed={column === DONE_ACTIONS}
            column={column}
            tabName={tab}
            onNoteChanged={this.onNoteChanged(column)}
            onNoteAdded={this.onNoteAdded}
            notes={this.state.notes[column].results.map((note) => {
                if (note.id === 46931) {
                    return {
                        ...note,
                        deleted: true
                    }
                }
                return note;
            })}
            onNoteDeleted={this.onNoteDeleted(column)}
            hasFilters={fields.size > 0} />;
    }

    render() {

        const isActions = this.state.currentTab === DUE_FILTER_TAB
        const filterTitleKey = (() => {
            if (isActions) {
                return 'actions.and.notes.tab.sort.by.date.to';
            }
            return 'actions.and.notes.tab.sort.by.updated.at';
        })();

        const fields = getSearchForm({
            searchForm: this.props.searchForm,
            client: this.props.client,
            fullReload: true,
            excludedFields: ["propertyNotesFilter", "companyNotesFilter"],
            onSearch: this.reloadData
        })

        const notesContent = (() => {
            if (this.state.currentTab === ALL_FILTER_TAB) {
                if (!this.props.hasAccessToAssignableActions) {
                    return this.renderNoteTabContent(ALL_FILTER_TAB, ALL_NOTES, fields);
                }
                return <div className="actions-columns">
                    {this.renderNoteTabContent(ALL_FILTER_TAB, PROPERTY_NOTES, fields)}
                    {this.renderNoteTabContent(ALL_FILTER_TAB, COMPANY_NOTES, fields)}
                </div>
            }
            if (!this.props.hasAccessToAssignableActions) {
                return this.renderNoteTabContent(DUE_FILTER_TAB, DUE_ACTIONS, fields);
            }
            return <div className="actions-columns">
                {this.renderNoteTabContent(DUE_FILTER_TAB, DUE_ACTIONS, fields)}
                {this.renderNoteTabContent(DUE_FILTER_TAB, DONE_ACTIONS, fields)}
            </div>;
        })();

        const columnForRecords = this.state.currentTab === DUE_FILTER_TAB ? DUE_ACTIONS : ALL_NOTES;

        const records = (this.state.stats[columnForRecords].actionsCount || 0) + (this.state.stats[columnForRecords].notesCount || 0);

        const recordsFound = (() => {
            if (this.props.hasAccessToAssignableActions && this.state.currentTab === ALL_FILTER_TAB) {
                return translateANPCCounter(this.props.t, {
                    properties: this.state.stats[PROPERTY_NOTES].propertiesCount || 0,
                    companies: this.state.stats[COMPANY_NOTES].companiesCount || 0,
                    actions: this.state.stats[PROPERTY_NOTES].actionsCount + this.state.stats[COMPANY_NOTES].actionsCount || 0,
                    notes: this.state.stats[PROPERTY_NOTES].notesCount + this.state.stats[COMPANY_NOTES].notesCount || 0
                });
            }

            return translateANPCCounter(this.props.t, {
                properties: this.state.filterVisual.parentType.value.includes('PROPERTY') ? this.state.stats[columnForRecords].propertiesCount || 0 : 0,
                companies: this.state.filterVisual.parentType.value.includes('COMPANY') ? this.state.stats[columnForRecords].companiesCount || 0 : 0,
                actions: this.state.stats[columnForRecords].actionsCount || 0,
                notes: this.state.stats[columnForRecords].notesCount || 0
            });
        })();

        const resultsFound = (() => {
            if (this.props.hasAccessToAssignableActions) {
                return null;
            }
            return this.state.initialStatsLoading ? this.props.t('actions.and.notes.results.stats.loading') : this.props.t('actions.and.notes.results.stats.records', { count: records });
        })();

        const searchType = this.props.searchType;
        return (
            <div className="wrapper-content">
                {this.state.showExportDialog &&
                    <CNotesExportPopup
                        records={records}
                        recordsFound={recordsFound}
                        isActionsTab={this.state.currentTab == DUE_FILTER_TAB}
                        onClose={this.toggleExportNotes}
                        onDone={this.onExportNext}
                        user={this.props.user}
                    />
                }
                <Header title={this.props.route.backLabel} />
                <div className="main">
                    <div className="main-content show-main-scroll">
                        <div className="container">
                            <Menu className="hide-mobile" />
                            <div className="dashboard actions-and-notes">
                                <div className="block-tile">
                                    <div className="box-tile" style={{ marginBottom: this.props.hasAccessToAssignableActions ? 0 : 10,  marginTop: "10px" }}>
                                        <div className="box-nav-tabs">
                                            <ul className="nav-tabs tabs-fixed">
                                                {!this.props.hasAccessToAssignableActions && <li className={isActions ? "active" : ""}>
                                                    <a onClick={this.setActiveTab.bind(this, DUE_FILTER_TAB)}><CLabel k="actions.and.notes.actions.due" /></a>
                                                    {isActions &&
                                                        <div className="aan-tab-label hide-mobile">
                                                            <CLabel k="actions.and.notes.tab.sort.by.date.to" />
                                                        </div>
                                                    }
                                                </li>}
                                                {this.props.hasAccessToAssignableActions && <li className={isActions ? "active" : ""}>
                                                    <a onClick={this.setActiveTab.bind(this, DUE_FILTER_TAB)}><CLabel k="actions.and.notes.actions.tab" /></a>
                                                    <div className="aan-tab-label hide-mobile">
                                                        <CLabel k="actions.and.notes.tab.track.actions" />
                                                    </div>
                                                </li>}
                                                <li className={!isActions ? "active" : ""}>
                                                    <a onClick={this.setActiveTab.bind(this, ALL_FILTER_TAB)}>
                                                        <CLabel k="actions.and.notes.all.notes.and.actions" />
                                                    </a>
                                                    {!isActions &&
                                                        <div className="aan-tab-label hide-mobile">
                                                            <CLabel k="actions.and.notes.tab.sort.by.updated.at" />
                                                        </div>
                                                    }
                                                    {!isActions && <div className="box-dropdown nav-action text-right delete-menu">
                                                        <CThreeDots onClick={() => { this.setState({ isDropdownOpen: !this.state.isDropdownOpen }) }} disabled={false} />
                                                        {this.state.isDropdownOpen && <div className="show-delete-dropdown form" style={{
                                                            top: 25,
                                                            right: 0
                                                        }}>
                                                            <div className="box-checkbox middle" style={{
                                                                padding: "8px 16px",
                                                            }}>
                                                                <input type="checkbox" id="check2" checked={this.state.filter.isDeleted === null} onChange={this.toggleShowDeleted}/>
                                                                <label for="check2" style={{
                                                                    paddingTop: 3
                                                                }}>
                                                                    <CLabel k="actions.and.notes.showDeleted" />
                                                                </label>
                                                            </div>
                                                        </div>}
                                                    </div>}
                                                    {this.props.hasAccessToAssignableActions && isActions && <div className="aan-tab-label hide-mobile">
                                                        <CLabel k="actions.and.notes.tab.complete.record" />
                                                    </div>
                                                }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="block-tabs search-filter">
                                            <div className="box-tab" style={{ display: "block" }}>
                                                <div className="box-nav">
                                                    <div className="aan-tab-label-mobile show-mobile">
                                                        <CLabel k={filterTitleKey} />
                                                    </div>
                                                </div>
                                                <form className="form form-small aan-form border-between">
                                                    {/* {isActions && this.props.hasAccessToAssignableActions &&
                                                        <div className="dofl row">
                                                            {this.props.noteSharing &&
                                                                <div className="d-flex justify-content-between">
                                                                </div>
                                                            }
                                                        </div>
                                                    } */}
                                                    <div className="dofl row">
                                                        <div className="an-filter-section d-flex flex-row flex-wrap justify-content-between align-items-center">
                                                            {this.props.noteSharing &&
                                                                <div className={"an-writtenby box-checkbox middle" + (this.state.filterVisual.createdById.value.length ? " bold" : "")}>
                                                                    <Trans i18nKey="actions.and.notes.filter.writtenBy" values={{
                                                                        by: this.state.filterVisual.createdById.titleKey ? this.props.t(this.state.filterVisual.createdById.titleKey) : this.state.filterVisual.createdById.title
                                                                    }}>
                                                                        <span>Written by</span>
                                                                        <CInlineDropdown
                                                                            as="a"
                                                                            onSelect={this.setCreatedBy}
                                                                            items={this.state.createdByList.map((item) => ({ ...item, title: item.titleKey ? this.props.t(item.titleKey) : item.title }))}>
                                                                            me
                                                                        </CInlineDropdown>
                                                                    </Trans>
                                                                </div>
                                                            }
                                                            <div className="an-mentioning d-flex flex-row justify-content-between align-items-center" style={{ width: "265px" }}>
                                                                <label htmlFor="noteContains" className={"pull-left" + (this.state.filter.text ? " bold" : "")} style={{ marginRight: "10px" }}><CLabel k="actions.and.notes.filter.contains.label" /></label>
                                                                <input id="noteContains" className="form-control middle pull-right" onChange={this.setContainsText} value={this.state.filter.text || ""} placeholder={this.props.t("actions.and.notes.filter.contains.placeholder")} />
                                                            </div>
                                                            <div className="an-created-by" style={{ maxWidth: "200px" }}>
                                                                {/* <CLabel k="actions.and.notes.filter.createdAt.label" /> <OverlayTrigger trigger="click" placement="bottom" overlay={dateRangePopup}><a><CLabel k="actions.and.notes.filter.createdAt.anytime" /></a></OverlayTrigger> */}
                                                                <Trans i18nKey="controls.date.range.created.or.changed">
                                                                    <span>Created or changed</span>
                                                                    <CDateRange controlValue={this.state.filter.controlValue} onChange={this.setUpdatedDate} />
                                                                </Trans>
                                                            </div>
                                                            <div className="an-add-filters">
                                                                <div style={{ lineHeight: "20px" }} >
                                                                    {fields.size === 0 &&
                                                                        <><a onClick={this.showFilters}><CLabel k="actions.and.notes.filter.search.add.filters" /></a><br /></>
                                                                    }
                                                                    {this.state.filterVisual.useSavedSearch &&
                                                                        <><a onClick={this.showFilters}><CLabel k="actions.and.notes.filter.search.filters.more" /></a><br /></>
                                                                    }
                                                                    <CInlineDropdown
                                                                        title={this.props.t("actions.and.notes.filter.search.use.saved")}
                                                                        as="a"
                                                                        onSelect={this.loadFilter}
                                                                        items={[...this.getSavedFilters()]} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {fields.size > 0 && !this.state.filterVisual.useSavedSearch &&
                                                        <div className="search-filter row">
                                                            <CSearchFilter
                                                                isMobile={this.state.isMobile}
                                                                fields={fields}
                                                                searchType={searchType}
                                                                onSearch={this.reloadData}
                                                                onShowFilters={this.showFilters}
                                                                onClearFilters={this.clearFilters}
                                                            />
                                                        </div>
                                                    }
                                                    {this.state.filterVisual.useSavedSearch &&
                                                        <div className="dofl search-filter row">
                                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                                <div><i><CLabel k="actions.and.notes.filter.search.saved.apply" /> <span className="bold-shadow">{this.state.filterVisual.useSavedSearch.name}. </span></i></div>
                                                                <div><a onClick={this.clearFilters}><CLabel k="quick.find.clear" /></a></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="dofl row">
                                                        <div className="d-flex justify-content-between">
                                                            {resultsFound ? <div>
                                                                <span style={this.state.statsLoading ? { opacity: 0.5 } : {}}>{resultsFound}</span>
                                                                <Trans i18nKey="actions.and.notes.results.showing">
                                                                    <span>showing</span>
                                                                    <CInlineDropdown
                                                                        title={this.state.filterVisual.parentType.item}
                                                                        as="a"
                                                                        onSelect={this.setParentType}
                                                                        items={this.getParentTypesList()} />
                                                                </Trans>
                                                            </div> :
                                                                <div className={"box-checkbox row-flex-three middle" + (this.state.filterVisual.assignedToId?.value?.length ? " bold" : "")}>
                                                                    {this.props.noteSharing && isActions && <Trans i18nKey="actions.and.notes.filter.assignedTo" values={{
                                                                        assignee: this.state.filterVisual.assignedToId.titleKey ? this.props.t(this.state.filterVisual.assignedToId.titleKey) : this.state.filterVisual.assignedToId.title
                                                                    }}>
                                                                        <span>Assigned to</span>
                                                                        <CInlineDropdown
                                                                            as="a"
                                                                            onSelect={this.setAssignedTo}
                                                                            items={this.state.assignedToList.map((item) => ({ ...item, title: item.titleKey ? this.props.t(item.titleKey) : item.title }))}>
                                                                            me
                                                                        </CInlineDropdown>
                                                                    </Trans>}
                                                                </div>}
                                                            <div className={`action-btn-wrapper ${this.state.isMobile && 'd-flex flex-nowrap'}`}>
                                                                <a className={"action-btn" + (!this.getHighestTotalCount() ? " disabled unselectable" : "")}
                                                                    onClick={this.toggleExportNotes}
                                                                    style={{ padding: 0, ...(this.state.isMobile && { whiteSpace: 'nowrap' }) }}><i className="fa fa-clipboard" style={{ fontSize: "14px" }}></i>&nbsp;<CLabel k="actions.and.notes.export" /></a>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <CInlineDropdown
                                                                    title={<GotoTitle isMobile={this.state.isMobile} />}
                                                                    as="a"
                                                                    className="no-accent action-btn"
                                                                    onSelect={(item) => this.gotoSearch(item.value)}
                                                                    style={{ ...(this.state.isMobile && { whiteSpace: 'nowrap' }) }}
                                                                    items={this.getExportableTypes(
                                                                        this.state.filter.parentType.indexOf("PROPERTY") == -1,
                                                                        this.state.filter.parentType.indexOf("COMPANY") == -1
                                                                    )} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-tile block-tabs">
                                        {(!this.state.showLoader || (this.state.showLoader && this.hasResults() > 0))
                                            && notesContent
                                        }
                                    </div>
                                    {this.state.showLoader
                                        && <div className="search-block-loader"><div className="loader"><CLabel k="loading" /></div></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}

const GotoTitle = () => {
    return <React.Fragment>
        <i className="fa fa-arrow-right" style={{ "marginRight": "3px", "fontSize": "13px", "paddingTop": "1px" }}></i><CLabel k="actions.and.notes.exportable.goto" />...
    </React.Fragment>
}

export default withTranslation()(Connect(ActionsAndNotes2, {
    dispatch: (dispatch) => {
        return {
            addValueToForm: (name, value) => dispatch({ type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value }),
            initSearchForm: (type) => dispatch({ type: 'INIT_SEARCH_FORM', searchType: type, storageKey: "LENAV_PREVIOUS_SEARCH_FORM_AN", version: Math.random() }),
            toggleRenameExportFilename: (state) => dispatch({ type: "TOGGLE_POPUP_FILE_RENAME", data: { state: state } }),
            checkWsConnection: (token) => dispatch({ type: "CHECK_WS_CONNECTION", token: token }),
            setSearchType: (searchType) => dispatch({ type: 'SET_SEARCH_TYPE', searchType: searchType }),
            setStorageKey: (storageKey) => dispatch({ type: 'SET_STORAGE_KEY', storageKey: storageKey }),
            toggleFilterPopup: (form, onSearch) => {
                const toggle = () => dispatch({ type: 'TOGGLE_POPUP_FILTER' })
                const search = () => {
                    toggle()
                    onSearch()
                }
                dispatch({ type: 'TOGGLE_POPUP_FILTER', data: { originalForm: form, onSearch: search, onCancel: toggle, count: " ", previousSearchKey: "LENAV_PREVIOUS_SEARCH_FORM_AN", defaultValues: { storageKey: "LENAV_PREVIOUS_SEARCH_FORM_AN" } } })
            },
            setForm: (form) => dispatch({ type: 'SET_FILTER_FORM', form: form })
        }
    },

    state: (state) => {
        return {
            activeTab: state.notes.tabs.activeTab,
            filter: state.notes.tabs.filter,
            filtersToHide: state.app.filtersToHide,
            noteSharing: state.session
                && state.session.user
                && state.session.user.client
                && state.session.user.client.noteSharing,
            translation: state.translation,
            user: state.session && state.session.user,
            client: state.session?.user?.client,
            hasAccessToAssignableActions: Utils.hasFeature(state.session?.user?.client, "AssignableActions"),
            searchForm: state.searchForm.form,
            savedFilters: state.savedFilters.filters,
            sharedFilters: state.savedFilters.shared
        }
    }
}));

