import { CLabel } from "./CLabel";
require("./cicon_label.scss");

export default ({
  k,
  icon,
  className,
  nowrap = true,
  onClick,
  children,
  ...rest
}) => {
  if (nowrap) {
    className += " nowrap";
  }
  return (
    <a className={"cicon_label " + className} {...rest} onClick={onClick}>
      <i className={"fa " + icon + " icon-left"}></i>
      <CLabel k={k} />
      {children}
    </a>
  );
};
