Connect = require '../helpers/Connect'
UserUtils = require '../helpers/UserUtils'
Utils = require '../helpers/Utils'
Header = require '../pages/parts/Header'
Menu = require('../pages/parts/Menu').default
{ CLabel } = require '../cmp/CLabel'
{ API } = require '../redux/Store'

An = require '../helpers/Analytics'
{ withTranslation } = require 'react-i18next' 
CFilterDropdown = require '../cmp/filters/CFilterDropdown'
{ getDateLocale } = require "../helpers/LocaleHelpers"
{ getLanguageOptions } = require "../helpers/LanguageOptions"
{ extend } = require '../helpers/UtilsHelpers'
Moment = require "moment"

module.exports = withTranslation() Connect createReactClass(
  displayName: 'Settings'

  getInitialState: ()->
    changePasswordForm:
      oldPassword: ""
      newPassword: ""
      newPasswordConfirm: ""
      submitButtonDisabled: true
    settings:
      user:
        emailWeeklyDigest: !!@props.user?.emailWeeklyDigest
        remindAboutActions: !!@props.user?.remindAboutActions
        remindAboutActionsInWeek: !!@props.user?.remindAboutActionsInWeek
        remindAboutActionsIn3Days: !!@props.user?.remindAboutActionsIn3Days
        remindAboutActionsIn2Days: !!@props.user?.remindAboutActionsIn2Days
        remindAboutActionsInDay: !!@props.user?.remindAboutActionsInDay
        remindAboutActionsOnDueDay: !!@props.user?.remindAboutActionsOnDueDay
        remindAboutActionsWhenOverdue: !!@props.user?.remindAboutActionsWhenOverdue
        sendRemindersToMeAs: @setSendRemindersToMeAs(@props.user)
        language: @props.user?.language
      saveButtonDisabled: true

  componentDidMount: () ->
    An.other.profile()
    
  setSendRemindersToMeAs: (user)->
    values = []
    if user?.notificationByEmail
      values.push('asEmail')  
    if user?.notificationByPUSH
      values.push('asNotifications')
    return values

  resetErrors: ->
    @setState
      newPasswordConfirmError: null
      oldPasswordError: null
      newPasswordError: null

  setForm: (e)->
    form = @state.changePasswordForm
    @resetErrors()
    target = e.target
    form[target.name] = target.value
    form.submitButtonDisabled = !((form.oldPassword || @props.isAliasEnabled) && form.newPassword && form.newPasswordConfirm)

    @setState @state.changePasswordForm

  changePassword: (e)->
    e.preventDefault()
    form = @state.changePasswordForm
    if (form.oldPassword || @props.isAliasEnabled) && form.newPassword && form.newPasswordConfirm
      if form.newPassword == form.newPasswordConfirm
        API.changePassword form, null, null, (err)=>
          if err?.error
            @setState oldPasswordError: React.createElement(CLabel, {"k": "settings.old.password.incorrect"}), changePasswordForm: extend(@state.changePasswordForm, submitButtonDisabled: true)
          else
            @props.toggleCustomPopup()
            @setState changePasswordForm: oldPassword: "", newPassword: "", newPasswordConfirm: "", submitButtonDisabled: true
      else
        @setState newPasswordConfirmError: React.createElement(CLabel, {"k": "settings.new.password.must.match.confirm.new.password.error"}), changePasswordForm: extend(@state.changePasswordForm, submitButtonDisabled: true)
    else
      if !form.oldPassword
        @setState oldPasswordError: React.createElement(CLabel, {"k": "settings.old.password.empty.error"})
      if !form.newPassword
        @setState newPasswordError: React.createElement(CLabel, {"k": "settings.new.password.empty.error"})
      if !form.newPasswordConfirm
        @setState newPasswordConfirmError: React.createElement(CLabel, {"k": "settings.confirm.new.password.empty.error"})

  updateBooleanField: (fieldName, e)->
    settings = @state.settings
    if (fieldName == 'sendRemindersToMeAs')
      sendRemindersToMeAs = settings.user.sendRemindersToMeAs || []
      if sendRemindersToMeAs.includes(e)
        sendRemindersToMeAs = sendRemindersToMeAs.filter((item)=> item != e)
      else
        sendRemindersToMeAs.push(e)
      settings.user.sendRemindersToMeAs = sendRemindersToMeAs
    else
      settings.user[fieldName] = if !e?.target then e else e.target.checked
    settings.saveButtonDisabled = false
    settings.changesSaved = false
    @setState settings: settings
    @saveSettings()

  saveSettings: ()->
    @state.settings.user.notificationByEmail = @state.settings.user.sendRemindersToMeAs.includes('asEmail')
    @state.settings.user.notificationByPUSH = @state.settings.user.sendRemindersToMeAs.includes('asNotifications')
    user = extend(@props.user, @state.settings.user)
    settings = @state.settings
    settings.saveButtonDisabled = true
    API.updateUser user, (user)=>
      settings.changesSaved = true
      settings.user.sendRemindersToMeAs = @setSendRemindersToMeAs(user)
      @setState settings: settings
      @props.setUser(user)

  languageOptions: ()->
    return getLanguageOptions(@props.t, @props.client).map((item)=>
      {
        ...item,
        label: item.label + " (" + item.value.toUpperCase() + ")",
        labelSelected: React.createElement("span", null,
          React.createElement("i", {"className": "fa fa-globe"}),
          (item.label), " (", React.createElement("span", {"className": "uppercase"}, (item.value)), """)
""")
      }
    )

  selectLanguage: (_, value)->
    @props.i18n.changeLanguage(value)
    document.title = @props.t(Utils.getEnvStyle().title)
    Moment.locale(getDateLocale(value))
    @setState({
      ...@state,
      settings: {
        ...@state.settings,
        user: {
          ...@state.settings.user,
          language: value
        }
      }
    }, ()=>
      @saveSettings()
    )

  render: ()->
    React.createElement("div", {"className": "wrapper-content"},
      React.createElement(Header, {"backLabel": (@props.route.backLabel), "title": (@props.t("header.profileName", { firstName: @props.user?.firstName, lastName: @props.user?.lastName }))}),
      React.createElement("div", {"className": "main"},
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": "container"},
            React.createElement(Menu, {"className": "hide-mobile"}),
            React.createElement("div", {"className": "dashboard"},
              React.createElement("div", {"className": "dashboard-group clearfix"},
                React.createElement("div", {"className": "block-tile row-half"},
                  React.createElement("div", {"className": "name-block mobile-padding"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "title.settings"}))),
                  React.createElement("div", {"className": "box-tile"},
                    (@languageOptions().length > 1 && React.createElement("div", {"className": "tile-group"},
                      React.createElement("form", {"className": "form form-small"},
                        React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": "settings.select.language"})),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "box-checkbox settings-language-select"},
                            React.createElement(CFilterDropdown, { \
                              "name": 'language',  \
                              "id": "language",  \
                              "className": "text-left auto-export-dropdown",  \
                              "onChange": (@selectLanguage),  \
                              "value": (@props.i18n.resolvedLanguage),  \
                              "nullable": (false),  \
                              "options": (@languageOptions())
                            })
                          )
                        )
                      )
                    )),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("form", {"className": "form form-small"},
                        React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": "settings.weekly.digest"})),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "box-checkbox"},
                            React.createElement("input", {"type": "checkbox", "checked": (!!@state.settings.user.emailWeeklyDigest), "onChange": (@updateBooleanField.bind @, "emailWeeklyDigest"), "value": (1), "id": "check1"}), React.createElement("label", {"htmlFor": "check1"}, React.createElement(CLabel, {"k": "settings.check1.email.me.weekly"}))
                          )
                        )
                      )
                    ),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("form", {"className": "form form-small"},
                        React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": "settings.action.reminders"})),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "box-checkbox"},
                            React.createElement("input", {"type": "checkbox", "checked": (!!@state.settings.user.remindAboutActions), "value": (1), "onChange": (@updateBooleanField.bind @, "remindAboutActions"), "id": "remindAboutActions"}), React.createElement("label", {"htmlFor": "remindAboutActions"}, React.createElement(CLabel, {"k": "settings.action.reminder.enabled"}))
                          )
                        ),
                        (if !!@state.settings.user.remindAboutActions
                          React.createElement("div", {"className": "remind-actions"},
                            React.createElement("div", {"className": "box-control"},
                              React.createElement("div", {"className": "box-checkbox"},
                                React.createElement("input", {"type": "checkbox", "checked": (!!@state.settings.user.remindAboutActionsInWeek), "value": (1), "onChange": (@updateBooleanField.bind @, "remindAboutActionsInWeek"), "id": "remindAboutActionsInWeek"}), React.createElement("label", {"htmlFor": "remindAboutActionsInWeek"}, React.createElement(CLabel, {"k": "settings.action.reminder.one.week.ahead"}))
                              )
                            ),
                            React.createElement("div", {"className": "box-control"},
                              React.createElement("div", {"className": "box-checkbox"},
                                React.createElement("input", {"type": "checkbox", "checked": (!!@state.settings.user.remindAboutActionsIn3Days), "value": (1), "onChange": (@updateBooleanField.bind @, "remindAboutActionsIn3Days"), "id": "remindAboutActionsIn3Days"}), React.createElement("label", {"htmlFor": "remindAboutActionsIn3Days"}, React.createElement(CLabel, {"k": "settings.action.reminder.three.business.days.ahead"}))
                              )
                            ),
                            React.createElement("div", {"className": "box-control"},
                              React.createElement("div", {"className": "box-checkbox"},
                                React.createElement("input", {"type": "checkbox", "checked": (!!@state.settings.user.remindAboutActionsIn2Days), "value": (1), "onChange": (@updateBooleanField.bind @, "remindAboutActionsIn2Days"), "id": "remindAboutActionsIn2Days"}), React.createElement("label", {"htmlFor": "remindAboutActionsIn2Days"}, React.createElement(CLabel, {"k": "settings.action.reminder.two.business.days.ahead"}))
                              )
                            ),
                            React.createElement("div", {"className": "box-control"},
                              React.createElement("div", {"className": "box-checkbox", "style": (opacity: 0.6)},
                                React.createElement("input", {"type": "checkbox", "disabled": "true", "checked": (!!@state.settings.user.remindAboutActionsInDay), "value": (1), "onChange": (@updateBooleanField.bind @, "remindAboutActionsInDay"), "id": "remindAboutActionsInDay"}), React.createElement("label", {"style": (cursor: "default"), "htmlFor": "remindAboutActionsInDay"}, React.createElement(CLabel, {"k": "settings.action.reminder.one.business.day.ahead"}))
                              )
                            ),
                            React.createElement("div", {"className": "box-control"},
                              React.createElement("div", {"className": "box-checkbox", "style": (opacity: 0.6)},
                                React.createElement("input", {"type": "checkbox", "disabled": "true", "checked": (!!@state.settings.user.remindAboutActionsOnDueDay), "value": (1), "onChange": (@updateBooleanField.bind @, "remindAboutActionsOnDueDay"), "id": "remindAboutActionsOnDueDay"}), React.createElement("label", {"style": (cursor: "default"), "htmlFor": "remindAboutActionsOnDueDay"}, React.createElement(CLabel, {"k": "settings.action.reminder.on.the.due.date"}))
                              )
                            ),
                            React.createElement("div", {"className": "box-control"},
                              React.createElement("div", {"className": "box-checkbox"},
                                React.createElement("input", {"type": "checkbox", "checked": (!!@state.settings.user.remindAboutActionsWhenOverdue), "value": (1), "onChange": (@updateBooleanField.bind @, "remindAboutActionsWhenOverdue"), "id": "remindAboutActionsWhenOverdue"}), React.createElement("label", {"htmlFor": "remindAboutActionsWhenOverdue"}, React.createElement(CLabel, {"k": "settings.action.reminder.when.overdue"}))
                              )
                            ),
                            React.createElement("div", {"className": "remind-actions-subsection"},
                              React.createElement("span", {"className": "remind-actions-subsection-title"}, React.createElement(CLabel, {"k": "settings.send.reminders.to.me"})),
                              React.createElement("div", {"className": "remind-actions"},
                                React.createElement("div", {"className": "box-control"},
                                  React.createElement("div", {"className": "box-checkbox"},
                                    React.createElement("input", {"type": "checkbox", "checked": (@state.settings.user.sendRemindersToMeAs?.includes('asNotifications')), "name": "asNotifications", "onChange": (@updateBooleanField.bind @, "sendRemindersToMeAs", "asNotifications"), "id": "asNotifications"}), React.createElement("label", {"htmlFor": "asNotifications"}, React.createElement(CLabel, {"k": "settings.action.reminder.send.as.notifications"}))
                                  )
                                ),
                                React.createElement("div", {"className": "box-control"},
                                  React.createElement("div", {"className": "box-checkbox"},
                                    React.createElement("input", {"type": "checkbox", "checked": (@state.settings.user.sendRemindersToMeAs?.includes('asEmail')), "name": "asEmail", "onChange": (@updateBooleanField.bind @, "sendRemindersToMeAs", "asEmail"), "id": "asEmail"}), React.createElement("label", {"htmlFor": "asEmail"}, React.createElement(CLabel, {"k": "settings.action.reminder.send.as.email.messages"}))
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                ),
                React.createElement("div", {"className": "block-tile row-half"},
                  React.createElement("div", {"className": "name-block mobile-padding"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.change.password"}))),
                  React.createElement("div", {"className": "box-tile"},
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("form", {"className": "form form-small", "autoComplete": "off", "onSubmit": (@changePassword)},
                        React.createElement("div", {"className": "box-form-group"},
                          (if !@props.isAliasEnabled
                            React.createElement("div", {"className": "box-control"},
                              React.createElement("input", {"type": "password", "autoComplete": "off", "name": "oldPassword", "value": (@state.changePasswordForm.oldPassword || ''), "onChange": (@setForm), "className": "form-control", "placeholder": (@props.t("settings.placeholder.enter.your.current.password"))}),
                              (if @state.oldPasswordError
                                React.createElement("div", {"className": "message-error under-control"}, (@state.oldPasswordError))
                              )
                            )
                          ),
                          React.createElement("div", {"className": "box-control"},
                            React.createElement("input", {"type": "password", "autoComplete": "off", "name": "newPassword", "value": (@state.changePasswordForm.newPassword || ''), "onChange": (@setForm), "className": "form-control", "placeholder": (@props.t("settings.placeholder.enter.new.password"))}),
                            (if @state.newPasswordError
                              React.createElement("div", {"className": "message-error under-control"}, (@state.newPasswordError))
                            )
                          ),
                          React.createElement("div", {"className": "box-control"},
                            React.createElement("input", {"type": "password", "autoComplete": "off", "name": "newPasswordConfirm", "value": (@state.changePasswordForm.newPasswordConfirm || ''), "onChange": (@setForm), "className": "form-control", "placeholder": (@props.t("settings.placeholder.repeat.new.password"))}),
                            (if @state.newPasswordConfirmError
                              React.createElement("div", {"className": "message-error under-control"}, (@state.newPasswordConfirmError))
                            )
                          )
                        ),
                        React.createElement("div", {"className": "box-control-top text-right"},
                          React.createElement("button", {"type": 'submit', "className": "btn btn-red btn-normal #{if @state.changePasswordForm.submitButtonDisabled then 'disabled'}", "onClick": (@changePassword)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.change.password"})))
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
),
state: (state)->
  translation: state.translation
  user: state.session?.user
  client: state.session?.user?.client
  isAliasEnabled: state.session?.isAliasEnabled
dispatch: (dispatch)->
  setUser: (user)-> dispatch(type: 'SET_USER', user: user)
  toggleCustomPopup: ()-> dispatch(type: 'TOGGLE_POPUP_CUSTOM', data: title: "settings.custom.popup.title", hideBody: true)