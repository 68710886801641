import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { configMomentLocales } from './momentLocales';
import Backend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import { getDateLocale } from './helpers/LocaleHelpers';
import Utils from './helpers/Utils.cjsx';
import Moment from 'moment';

const AVAILABLE_LANGUAGES = ['en', 'ru', 'fr', 'zh', 'es', 'pt-BR', 'it', 'hi', 'de'];
configMomentLocales(AVAILABLE_LANGUAGES);

const DETECTION_OPTIONS = {
    order: ['localStorage'],
    caches: ['localStorage']
};

const locizeOptions = {
    projectId: '4dea5b65-fe83-4e64-87bc-b734bf757bbf',
    referenceLng: 'en',
    version: 'latest',
    apiKey: config.LOCIZE_API_KEY || null
}

if (!config.LOCIZE_OFFLINE) {
    i18n.use(locizePlugin);
}


const clipString = (str) => str.replace(/ *\[[^]*\] */g, "");

const processKeywords = (en, otherLanguages) => {
    Object.keys(en).forEach(key => {
        if (typeof en[key] === 'object') {
            const otherLanguages2 = {};
            Object.keys(otherLanguages).forEach(lang => {
                otherLanguages2[lang] = otherLanguages[lang][key];
            });
            processKeywords(en[key], otherLanguages2);
            return;
        }
        en[key] = clipString(en[key]);
        Object.keys(otherLanguages).forEach(lang => {
            if (!otherLanguages[lang]) {
                otherLanguages[lang] = {};
            }
            if (otherLanguages[lang][key] === undefined) {
                otherLanguages[lang][key] = `/${en[key]}/`;
            }
            if (typeof otherLanguages[lang][key] === 'string') {
                otherLanguages[lang][key] = clipString(otherLanguages[lang][key]);
            }
        });
    });
    return en;
}

const processJson = (json) => {
    const en = json.en;
    const otherLanguages = {};
    Object.keys(json).filter(k => k !== 'en').forEach(key => {
        otherLanguages[key] = json[key];
    });
    processKeywords(en, otherLanguages);
    return json;
}

const requireJson = (lang, name) => {
    let json = {};
    try {
        json = require(`../locales/${lang}/${name}.json`);
    }
    catch (e) {
        console.warn(`Failed to load "../locales/${lang}/${name}.json"`, e);
    }
    return json;
}

const getResources = () => {
    const resources = {};
    if (config.LOCIZE_OFFLINE) {
        const enInternal = requireJson('en', 'internal_ui');
        AVAILABLE_LANGUAGES.forEach(lang => {
            const data = requireJson(lang, 'data');
            const ui = requireJson(lang, 'ui');
            resources[lang] = {
                data,
                ui,
                internal_ui: enInternal
            };
        });
    }

    return processJson(resources);
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: DETECTION_OPTIONS,
        debug: false,
        fallbackLng: "en",
        ns: ["ui", "data", "internal_ui"],
        interpolation: {
            escapeValue: false
        },
        backend: !config.LOCIZE_OFFLINE ? locizeOptions : undefined,
        resources: config.LOCIZE_OFFLINE ? getResources() : undefined,
        react: {
            useSuspense: true,
            bindI18n: 'languageChanged editorSaved'
        },
        saveMissing: false
    }, (err, t) => {
        if (err) {
            console.error(err);
        }
        Moment.locale(getDateLocale(i18n.resolvedLanguage));
        document.title = t(Utils.getEnvStyle().title);
    });

const onLanguageChanging = (lng) => {
    if (lng != i18n.resolvedLanguage) {
        localStorage.setItem('i18nPrevLng', i18n.resolvedLanguage);
    }
}

i18n.on('languageChanging', onLanguageChanging)


export default i18n;
