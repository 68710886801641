LENAV_SESSION = "LENAV_SESSION"
{ param } = require '../helpers/UtilsHelpers'
Moment = require 'moment-timezone'

localCache =
  data: {}
  remove: (url) ->
    delete localCache.data[url]
    return
  exist: (url) ->
    localCache.data.hasOwnProperty(url) and localCache.data[url] != null
  get: (url) ->
    console.log 'Getting in cache for url: ' + url
    localCache.data[url]
  set: (url, cachedData, callback) ->
    localCache.remove url
    localCache.data[url] = cachedData
    if typeof callback == 'function'
      callback cachedData
    return

activeApiCalls = {hash: "", calls: []}
abortExcluded = ['i', 'index', 'getFeaturedEventsForPopularSports', 'update']

GET = "GET"
POST = "POST"
UPDATE = "UPDATE"
DELETE = "DELETE"
PUT = "PUT"

showBigSpinner = (request)->
  activeApiCalls.calls.push request
#Utils.showSpinner()

hideBigSpinner = (request)->
  if activeApiCalls.calls.indexOf(request) > -1
    activeApiCalls.calls.splice(activeApiCalls.calls.indexOf(request), 1)
  if activeApiCalls.calls.length == 0
#Utils.hideSpinner()
    1

cancelActiveCalls = ()->
  request.abort() for request in activeApiCalls.calls when request? && request.apiMethod not in abortExcluded
  activeApiCalls =
    hash: window.location.hash
    calls: []

settings =  window.SETTINGS.realms[config.LE_ENV].SETTINGS

module.exports = (store)->
  doApiCall: (params)->
    if activeApiCalls.hash != window.location.hash
      cancelActiveCalls()

    context = if params.context then params.context + "/" else ""
    method = params.method
    data = params.data
    useCache = params.useCache
    expirationCacheTime = params.expirationCacheTime
    cacheWithoutLocaleKey = params.cacheWithoutLocaleKey
    ecb = params.ecb
    cb = params.cb
    fcb = params.fcb
    contentType = params.contentType || "application/json"
    httpMethod = params.httpMethod || POST
    doNotRedirect = params.doNotRedirect
    withoutSplash = params.withoutSplash

    token = (() =>
      if params.isTranslate || params.isImport
        return JSON.parse(localStorage.getItem(LENAV_SESSION))?.token2
      return params.token || JSON.parse(localStorage.getItem(LENAV_SESSION))?.token
    )()

    #console.log("Environment: " + config.LE_ENV)
    realm = window.SETTINGS.realms[config.LE_ENV]

    apiUrl = (() =>
      if params.isBanner
        return realm.BANNERS
      if params.isTranslate
        return realm.TRANSLATE_URL
      if params.isImport
        return realm.IMPORT_URL
      return realm.URL
    )()
    
    locale = "EN"

    key = "#{apiUrl}#{if !cacheWithoutLocaleKey then locale.code else ""}#{if context then context else ''}#{if method then method else ""}#{if data then "?#{JSON.stringify(data)}" else ""}"

    if params.generateUrl
      u = "#{apiUrl}#{context}#{method}"
      console.log(u)
      if params.withToken
        u+="?token=#{token}"
        u+=if params.queryParams then "&"+params.queryParams else ''
      return u

    url = "#{apiUrl}#{context}#{method}"
    if params.queryParams
      url += "?#{params.queryParams}"
    if params.addToken
      url += "&token=#{token}"

    if useCache && localCache.exist(url)
      cachePromise = localCache.get(url)
      cachePromise.then((res) =>
        cb?(res)
      )
      return
      
    xhr = null
    handleOnError = (err) ->
      status = xhr.status
      if xhr.status == 403
        path = window.location?.pathname?.trim()
        if path && path !="/" && path != "/home" && !sessionStorage.getItem("PREVIOUS_URL")
          sessionStorage.setItem("PREVIOUS_URL", path)
        store.dispatch(type:"LOGOUT")

      if params.method != "setting/ping" && xhr.status != 0
        store.dispatch(type:"CHECK_SERVER_IS_RUNNING")
      
      result = null
      try
        result =  if xhr.dataType == 'json' then JSON.parse(xhr.responseText) else xhr.responseText
      catch error
        result = xhr.responseText

      ecb?(result, status, err, xhr)
      fcb?(result, false)
      hideBigSpinner?(xhr) if !withoutSplash

    xhr = new XMLHttpRequest()
    xhr.open(httpMethod, url, true)
    xhr.setRequestHeader("Content-Type", contentType)
    xhr.setRequestHeader("X-Auth-Token", token)
    xhr.withCredentials = true
    xhr.dataType = params.dataType || 'json'
    xhr.timeout = 30000
    onload = (resolve) ->
      if xhr.status == 200
        resultData = null
        try
          resultData = if xhr.dataType == 'json' then JSON.parse(xhr.responseText) else xhr.responseText
        catch error
          resultData = xhr.responseText
        if useCache && resolve
          # localCache.set(url, resultData)
          resolve(resultData)
        #flux?.actions.putToCache key, useCache, resultData, expirationCacheTime

        try
          cb?(resultData)
        catch error
          console.error(error)
        try
          fcb?(resultData, true)
        catch error
          console.error(error)
        hideBigSpinner(xhr) if !withoutSplash
      else
        try
          handleOnError(JSON.parse(xhr.responseText))
        catch error
          # I think we can't process not structured data, just put to log
          handleOnError()
          console.error(error)

    xhr.onload = onload

    if useCache
      localCache.set(url, new Promise((resolve) ->
        xhr.onload = () -> onload(resolve)
        xhr.send(JSON.stringify(data))
      ))
    else
      xhr.send(JSON.stringify(data))

    showBigSpinner(xhr) if !withoutSplash
    xhr

  ping: (cb, ecb, fcb)->
    @doApiCall method: "setting/ping", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  trackAction: (action, cb, ecb, fcb)->
    @doApiCall method: "analytics/action", data: {action: action, deviceName: window.deviceName, deviceToken: window.deviceToken}, cb: cb, ecb: ecb, fcb: fcb
    gtag('event', action, {'send_to' : settings.googleAnalyticsId})

  getTrackedActivity: (form, cb, ecb, fcb)->
    @doApiCall method: "analytics/activity", httpMethod: POST, data: form, cb: cb, ecb: ecb, fcb: fcb

  getTrackedActivityGroupSessions: (form, cb, ecb, fcb)->
    @doApiCall method: "analytics/activityBySession", httpMethod: POST, data: form, cb: cb, ecb: ecb, fcb: fcb

  getTrackedSessions: (form, cb, ecb, fcb)->
    @doApiCall method: "analytics/sessions", httpMethod: POST, data: form, cb: cb, ecb: ecb, fcb: fcb

  exportAnalyticsActivity: (params, cb, ecb, fcb)->
    @doApiCall method: "analytics/export/actions", httpMethod: GET, generateUrl: true, withToken: true, queryParams: params, cb: cb, ecb: ecb, fcb: fcb

  exportAnalyticsSessions: (params, cb, ecb, fcb)->
    @doApiCall method: "analytics/export/sessions", httpMethod: GET, generateUrl: true, withToken: true, queryParams: params, cb: cb, ecb: ecb, fcb: fcb

  login: (email, password, deviceToken, cb, ecb, fcb)->
    @doApiCall method: "user/login", data: {email: email, password: password, deviceName: window.deviceName, deviceToken: deviceToken, timezone: Moment.tz.guess()}, cb: cb, ecb: ecb, fcb: fcb

  shadowLogin: (token, cb, ecb, fcb)->
    @doApiCall method: "user/shadow/" + token, httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  createShadowSession: (token, cb, ecb, fcb)->
    @doApiCall method: "user/shadow/user/" + token, httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  logout: (cb, ecb, fcb)->
    @doApiCall method: "user/logout", data: {deviceName: window.deviceName, deviceToken: window.deviceToken}, cb: cb, ecb: ecb, fcb: fcb

  currentSession: (cb, ecb, fcb)->
    @doApiCall method: "user/i", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  acceptLicense: (cb, ecb, fcb)->
    @doApiCall method: "user/license/accept", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  recoverPassword: (email, cb, ecb, fcb)->
    @doApiCall method: "user/password/recovery", httpMethod: PUT, data: {email: email}, cb: cb, ecb: ecb, fcb: fcb

  changePassword: (form, cb, ecb, fcb)->
    @doApiCall method: "user/password/change", httpMethod: PUT, data: form, cb: cb, ecb: ecb, fcb: fcb

  updateUser: (form, cb, ecb, fcb)->
    @doApiCall method: "user/update", httpMethod: PUT, data: form, cb: cb, ecb: ecb, fcb: fcb

  getClientUsers: (cb, ecb, fcb)->
    @doApiCall method: "user/clientUsers", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  emailAutocomplete: (email, cb, ecb, fcb)->
    @doApiCall method: "user/autocomplete?email=#{email}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  sendWelcomeEmail: (form, cb, ecb, fcb)->
    @doApiCall method: "user/email/welcome", data: form, cb: cb, ecb: ecb, fcb: fcb

  passwordReset: (form, cb, ecb, fcb)->
    @doApiCall method: "user/password/reset", data: form, cb: cb, ecb: ecb, fcb: fcb

  sendWeeklyDigest: (form, cb, ecb, fcb)->
    @doApiCall method: "note/digestToEmails", data: form, cb: cb, ecb: ecb, fcb: fcb

  getWeeklyDigest: (form, cb, ecb, fcb)->
    @doApiCall method: "weekly/digestViewEmail", data: form, httpMethod: POST, cb: cb, ecb: ecb, fcb: fcb

  getRecentActivity: (form, cb, ecb, fcb)->
    @doApiCall method: "user/recentActivity", httpMethod: POST, data: form, cb: cb, ecb: ecb, fcb: fcb

  exportUserActivity: (params, cb, ecb, fcb)->
    @doApiCall method: "user/exportRecentActivity", httpMethod: GET, generateUrl: true, withToken: true, queryParams: params, cb: cb, ecb: ecb, fcb: fcb

  exportPropertyByEmail: (params, cb, ecb, fcb)->
    @doApiCall method: params.url, httpMethod: POST, withToken: true, data: params.data, cb: cb, ecb: ecb, fcb: fcb

  search: (form, offset, limit, cb, ecb, fcb)->
    form.offset = offset
    form.limit = limit
    @doApiCall method: "search", data: form, cb: cb, ecb: ecb, fcb: fcb

  quickSearch: (form, cb, ecb, fcb)->
    @doApiCall method: "search/quick", data: form, cb: cb, ecb: ecb, fcb: fcb

  mapSearch: (form, cb, ecb, fcb)->
    @doApiCall method: "map/search", data: form, cb: cb, ecb: ecb, fcb: fcb

  count: (form, cb, ecb, fcb)->
    @doApiCall method: "search/count", data: form, cb: cb, ecb: ecb, fcb: fcb

  autocomplete: (fieldName, filterForm, cb, ecb, fcb)->
    @doApiCall method: "search/autocomplete", data: {fieldName: fieldName, filterForm: filterForm}, cb: cb, ecb: ecb, fcb: fcb

  dictionary: (fieldName, filterForm, cb, ecb, fcb)->
    @doApiCall method: "search/dictionary", data: {fieldName: fieldName, filterForm: filterForm}, cb: cb, ecb: ecb, fcb: fcb

  settings: (cb, ecb, fcb)->
    @doApiCall method: "setting", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getTwiddler: (cb, ecb, fcb)->
    @doApiCall method: "twiddler/get", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb
    
  getDefaultKeywords: (cb, ecb, fcb)->
    @doApiCall method: "twiddler/client/keywords", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  stopTwiddler: (cb, ecb, fcb)->
    @doApiCall method: "twiddler/stop", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  startTwiddler: (form, cb, ecb, fcb)->
    @doApiCall method: "twiddler/start", data: form, cb: cb, ecb: ecb, fcb: fcb

  startAliasSession: (form, cb, ecb, fcb)->
    @doApiCall method: "twiddler/startAliasSession", data: form, cb: cb, ecb: ecb, fcb: fcb

  getProperty: (id, cb, ecb, fcb)->
    @doApiCall method: "property/#{id}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  translate: (body, cb, ecb, fcb)->
    @doApiCall method: "translate", data: body, cb: cb, ecb: ecb, fcb: fcb

  requestHumanTranslation: (hash, lang, cb, ecb, fcb)->
    @doApiCall({ method: "humantranslation/#{hash}/#{lang}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb, isTranslate: true, dataType: "text" })

  getMapProperty: (id, cb, ecb, fcb)->
    @doApiCall method: "property/map/#{id}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getCompany: (id, cb, ecb, fcb)->
    @doApiCall method: "company/#{id}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getTagsNew: (cb, ecb, fcb)->
    @doApiCall method: "search/tags/new", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getTags: (cb, ecb, fcb)->
    @doApiCall method: "search/tags/", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  saveTag: (form, cb, ecb, fcb)->
    @doApiCall method: "search/tags/save", data: form, cb: cb, ecb: ecb, fcb: fcb

  addTag: (form, cb, ecb, fcb)->
    @doApiCall method: "search/tags/add", data: form, cb: cb, ecb: ecb, fcb: fcb

  deleteTag: (keyword, cb, ecb, fcb)->
    @doApiCall method: "search/tags/#{keyword}", httpMethod: DELETE, cb: cb, ecb: ecb, fcb: fcb

  getFilters: (cb, ecb, fcb)->
    @doApiCall method: "filter", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getFiltersShared: (cb, ecb, fcb)->
    @doApiCall method: "filter/shared", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  deleteFilter: (id, cb, ecb, fcb)->
    @doApiCall method: "filter/#{id}", httpMethod: DELETE, cb: cb, ecb: ecb, fcb: fcb

  getFilter: (id, cb, ecb, fcb)->
    @doApiCall method: "filter/#{id}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb
    
  getFilterLink: (id, cb, ecb, fcb)->
    @doApiCall method: "filter/link/#{id}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  saveFilter: (filterForm, cb, ecb, fcb)->
    @doApiCall method: "filter", data: filterForm, cb: cb, ecb: ecb, fcb: fcb

  createExportFilter: (filterForm, cb, ecb, fcb)->
    @doApiCall method: "filter/create/export", data: filterForm, cb: cb, ecb: ecb, fcb: fcb

  createLinkFilter: (filterForm, cb, ecb, fcb)->
    @doApiCall method: "filter/create/link", data: filterForm, cb: cb, ecb: ecb, fcb: fcb

  getDownloadReportUrl: (reportName, format, id, limit, data)->
    if id in [undefined, null, ""]
      console.error("Id must be supplied for report!")
      return

    if reportName in ["results", "property", "property-email"]
      #if format in ["pdf", "xlsx"]
      method = "export/#{reportName}/#{format}/"
      method += if id then id else ""

      @doApiCall method: method, generateUrl: true, withToken: true, queryParams: data && param(data)
      #else
      #  console.error("Wrong format, allowed formats are: 'pdf', 'xlsx'")
    else
      console.error("Wrong report type, allowed report types are: 'results', 'property'")

  getNotificationSettings: (cb, ecb, fcb)->
    @doApiCall method: "setting/notifications", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getNotificationsLogs: (cb, ecb, fcb)->
    @doApiCall method: "setting/notifications/log", httpMethod: GET, dataType:"text", cb: cb, ecb: ecb, fcb: fcb

  saveNotificationSettings: (settings, cb, ecb, fcb)->
    @doApiCall method: "setting/notifications", httpMethod: POST, data:settings, cb: cb, ecb: ecb, fcb: fcb

  saveBannerMessageSettings: (message, cb, ecb, fcb)->
    @doApiCall method: "setting/banner/message", httpMethod: POST, data: message: message, cb: cb, ecb: ecb, fcb: fcb

  getBannerMessageSettings: (cb, ecb, fcb)->
    @doApiCall method: "setting/banner/message", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getBannerMessage: (cb, ecb, fcb)->
    @doApiCall method: "message.json", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb, isBanner: true

  getRecents: (cb, ecb, fcb)->
    @doApiCall method: "search/property/recents", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getWatchList: (cb, ecb, fcb)->
    @doApiCall method: "watch", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  watchList: (watchFilter, searchFilter, page, cb, ecb, fcb)->
    @doApiCall method: "watch/listWatches?page=#{page}", httpMethod: POST, data: { watchFilter: watchFilter, searchFilter: searchFilter }, cb: cb, ecb: ecb, fcb: fcb
  
  getWatchCount: (cb, ecb, fcb)->
    @doApiCall method: "watch/stats", httpMethod: POST, data: {}, cb: cb, ecb: ecb, fcb: fcb

  updateWatchList: (propertyId, cb, ecb, fcb)->
    @doApiCall method: "watch?propertyId=#{propertyId}", httpMethod: PUT, cb: cb, ecb: ecb, fcb: fcb

  deleteWatch: (propertyId, cb, ecb, fcb)->
    @doApiCall method: "watch/#{propertyId}", httpMethod: DELETE, cb: cb, ecb: ecb, fcb: fcb

  getWatchListHistory: (cb, ecb, fcb)->
    @doApiCall method: 'watch/history', httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getWatchUsers: (cb, ecb, fcb)->
    @doApiCall method: 'watch/dictionary/users', httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  createNote: (note, cb, ecb, fcb)->
    cbDispatch = (data) -> 
      cb(data)
      store.dispatch(type:"LOAD_ACTIONS_TODO")
    @doApiCall method: "note", httpMethod: POST, data: note, cb: cbDispatch, ecb: ecb, fcb: fcb

  editNoteAndFilter: (note, cb, ecb, fcb)->
    cbDispatch = (data) -> 
      cb(data)
      store.dispatch(type:"LOAD_ACTIONS_TODO")
    @doApiCall method: "note/update-filter", httpMethod: PUT, data: note, cb: cbDispatch, ecb: ecb, fcb: fcb

  editNote: (note, cb, ecb, fcb)->
    cbDispatch = (data) -> 
      cb(data)
      store.dispatch(type:"LOAD_ACTIONS_TODO")
    @doApiCall method: "note", httpMethod: PUT, data: note, cb: cbDispatch, ecb: ecb, fcb: fcb

  deleteNote: (noteId, cb, ecb, fcb)->
    fcbDispatch = (data) -> 
      fcb()
      store.dispatch(type:"LOAD_ACTIONS_TODO")
    @doApiCall method: "note/#{noteId}", httpMethod: DELETE, cb: cb, ecb: ecb, fcb: fcbDispatch

  notesDictionaryAuthors: (cb, ecb, fcb)->
    @doApiCall method: "note/dictionary/authors", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  notesDictionaryAssignees: (cb, ecb, fcb)->
    @doApiCall method: "note/dictionary/assignees", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  notesDictionaryAssignable: (data, cb, ecb, fcb)->
    url = if data.propertyId then "note/dictionary/assignable/property/#{data.propertyId}" else "note/dictionary/assignable/company/#{data.companyId}";
    @doApiCall method: url, httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  notesProperty: (id, cb, ecb, fcb)->
    @doApiCall method: "note/property/#{id}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  notesHistory: (id, cb, ecb, fcb)->
    @doApiCall method: "note/#{id}/history", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  notesCompany: (id, cb, ecb, fcb)->
    @doApiCall method: "note/company/#{id}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  notesList: (notesFilter, searchFilter, page, cb, ecb, fcb)->
    @doApiCall method: "note/list?page=#{page}", httpMethod: POST, data: {notesFilter: notesFilter, searchFilter: searchFilter}, cb: cb, ecb: ecb, fcb: fcb

  notesStats: (notesFilter, searchFilter, cb, ecb, fcb)->
    @doApiCall method: "note/stats", httpMethod: POST, data: {notesFilter: notesFilter, searchFilter: searchFilter}, cb: cb, ecb: ecb, fcb: fcb

  getActionsToDo: (cb, ecb, fcb)->
    @doApiCall method: "note/actionsToDo", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getCompanyInvolvedNotes: (companyId, cb, ecb, fcb)->
    @doApiCall method: "note/company/#{companyId}/involved", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  filterNotes: (filter, cb, ecb, fcb)->
    @doApiCall method: "note", httpMethod: POST, data: filter, cb: cb, ecb: ecb, fcb: fcb

  getCharts: (cb, ecb, fcb)->
    @doApiCall method: "chart", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getFirstChart: (cb, ecb, fcb)->
    @doApiCall method: "chart/firstChart", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getDimensions: (cb, ecb, fcb)->
    @doApiCall method: "chart/dimensions", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  acceptExport: (exportId, cb, ecb, fcb)->
    @doApiCall method: "export/accept?exportId=#{exportId}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  downloadExport: (params, cb, ecb, fcb)->
    @doApiCall method: "export/download", httpMethod: GET, generateUrl: true, withToken: true, queryParams: params, cb: cb, ecb: ecb, fcb: fcb

  startExport: (form, cb, ecb, fcb)->
    @doApiCall method: "export/startExport", data: form, cb: cb, ecb: ecb, fcb: fcb

  getExportHistory: (form, cb, ecb, fcb)->
    @doApiCall method: 'export/history', httpMethod: POST, data: form, cb: cb, ecb: ecb, fcb: fcb

  getAutoExports: (cb, ecb, fcb)->
    @doApiCall method: 'export/autoExport', httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  saveAutoExport: (form, cb, ecb, fcb)->
    @doApiCall method: 'export/autoExport', httpMethod: POST, data: form, cb: cb, ecb: ecb, fcb: fcb

  deleteAutoExport: (exportId, cb, ecb, fcb)->
    @doApiCall method: "export/autoExport/#{exportId}", httpMethod: DELETE, cb: cb, ecb: ecb, fcb: fcb

  exportUserExportHistory: (params, cb, ecb, fcb)->
    @doApiCall method: "export/saveUserExportHistory", httpMethod: GET, generateUrl: true, withToken: true, queryParams: params, cb: cb, ecb: ecb, fcb: fcb

  clientAutocomplete: (client, cb, ecb, fcb)->
    @doApiCall method: "export/autocompleteClient?clientName=#{client}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  branchAutocomplete: (branch, clientId, cb, ecb, fcb)->
    @doApiCall method: "export/autocompleteBranch?branch=#{branch}&clientId=#{clientId}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  startAutoExport: (form, cb, ecb, fcb)->
    @doApiCall method: "export/startAutoExport", data: form, cb: cb, ecb: ecb, fcb: fcb

  addDevice: (app, token, cb, ecb, fcb)->
    @doApiCall method: "user/addDevice", data: {app: app, token: token}, cb: cb, ecb: ecb, fcb: fcb


# Import API
  startImport: (isSilent, cb, ecb, fcb)->
    @doApiCall method: "import/full", httpMethod: POST, data: {isSilent: isSilent}, cb: cb, ecb: ecb, fcb: fcb, isImport: true

  startAccessImport: (cb, ecb, fcb)->
    @doApiCall method: "import/access", httpMethod: POST, cb: cb, ecb: ecb, fcb: fcb, isImport: true

  startIndexing: (cb, ecb, fcb)->
    @doApiCall method: "import/reindex", httpMethod: POST, cb: cb, ecb: ecb, fcb: fcb, isImport: true

  startPgIndexing: (cb, ecb, fcb)->
    @doApiCall method: "import/reindexpg", httpMethod: POST, cb: cb, ecb: ecb, fcb: fcb, isImport: true

  abortImport: (cb, ecb, fcb)->
    @doApiCall method: "import/cancel", httpMethod: POST, cb: cb, ecb: ecb, fcb: fcb, isImport: true

  getImportHistory: (cb, ecb, fcb)->
    @doApiCall method: "import/history", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb, isImport: true

  getHistoryLog: (id, cb, ecb, fcb)->
    @doApiCall method: "import/history/#{id}", httpMethod: GET, dataType:"text", cb: cb, ecb: ecb, fcb: fcb, isImport: true

  getImportProgress: (cb, ecb, fcb)-> #ecb == cb 
    @doApiCall method: "import/log", httpMethod: GET, dataType:"text", cb: cb, ecb: cb, fcb: fcb, isImport: true

  getImportJob: (cb, ecb, fcb)-> #ecb == cb
    @doApiCall method: "import/job", httpMethod: GET, cb: cb, ecb: cb, fcb: fcb, isImport: true

  getImportSettings: (cb, ecb, fcb)->
    @doApiCall method: "import/settings", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb, isImport: true

  saveImportSettings: (settings, cb, ecb, fcb)->
    @doApiCall method: "import/settings", httpMethod: POST, data:settings, cb: cb, ecb: ecb, fcb: fcb, isImport: true
  
  getQueries: (cb, ecb, fcb)->
    @doApiCall method: "query/list", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb

  getQuery: (api, apiMethod, data, cb, ecb, fcb)->
    if apiMethod == "GET"
      @doApiCall method: api, httpMethod: GET, queryParams: param(data), cb: cb, ecb: ecb, fcb: fcb
    else
      @doApiCall method: api, data: data, httpMethod: apiMethod, cb: cb, ecb: ecb, fcb: fcb

  getCustomFieldSet: (clientId, cb, ecb, fcb)->
    @doApiCall method: "export/columns?clientId=#{clientId}", httpMethod: GET, cb: cb, ecb: ecb, fcb: fcb, useCache: true
