import React from "react";
import CSlickgridWrapper from "../CSlickgridWrapper";

const CDataTable = ({ data }) => {
  const columns = Object.keys(data[0]);

  return (
    <div className="query-results-table-wrapper">
      <CSlickgridWrapper data={data} columns={columns} />
    </div>
  );
};

export default React.memo(CDataTable);
